import './OperationsMain.scss';

import { useState } from "react";

import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import * as XLSX from "xlsx";

import { fetchTableOperations } from "../../slices/tableOperationSlice";
import { setSelectedInterval } from "../../slices/tableOperationSlice";
import { setSelectedYear } from "../../slices/tableOperationSlice";
import { setSelectedMonth } from "../../slices/tableOperationSlice";
import { setStartDate } from "../../slices/tableOperationSlice";
import { setEndDate } from "../../slices/tableOperationSlice";
import { setSelectedBranch } from "../../slices/tableOperationSlice";
import { setSelectedLine } from "../../slices/tableOperationSlice";

import { Box } from "@mui/material";
import { Divider } from "@mui/material";

import { LineDropdownFilter } from "../../resources/Dropdowns/Dropdowns";
import { BranchDropdown } from "../../resources/Dropdowns/Dropdowns";
import { IntervalDropdown } from "../../resources/Dropdowns/Dropdowns";
import { MainTitle } from "../../resources/Titles/Titles";
import { TableSkeleton } from "../../resources/Skeleton/Skeleton";
import { ErrorMessage } from "../../resources/Messages/Messages";
import { EmptyMessage } from "../../resources/Messages/Messages";
import { ButtonExport } from "../../resources/Buttons/Buttons";

import Filters from "../Widgets/Filters/Filters";
import OperationsMainTable from "./OperationsMainTable/OperationsMainTable";
import {
    AkmolaBranch,
    AktobeBranch,
    AtyrauBranch,
    EastKazakhstanBranch,
    KaragandaBranch, KyzylordaBranch, MangystauBranch, NorthKazakhstanBranch, PavlodarBranch, SouthKazakhstanBranch,
    WestKazakhstanBranch
} from "../../resources/Branch/Branch";

const OperationsMain = () => {
    const dispatch = useDispatch();
    const departures = useSelector(state => state.tableOperations.departures);
    const selectedBranch = useSelector(state => state.tableOperations.selectedBranch);
    const selectedLineEmergency = useSelector(state => state.tableOperations.selectedLineEmergency);
    const selectedInterval = useSelector(state => state.tableOperations.selectedInterval);
    const selectedYear = useSelector(state => state.tableOperations.selectedYear);
    const selectedMonth = useSelector(state => state.tableOperations.selectedMonth);
    const startDate = useSelector(state => state.tableOperations.startDate);
    const endDate = useSelector(state => state.tableOperations.endDate);
    // const searchData = useSelector(state => state.tableOperations.searchData);
    const status = useSelector(state => state.tableOperations.tableOperationsLoadingStatus);

    let content = null;

    // const filteredDeparture = _transformSearchOperations(departures, searchData).filter(departure => {
    //     return selectedBranch ? departure.branch_id === selectedBranch : true;
    // });

    const createFilters = (state) => {
        let filters = {};

        if (selectedInterval === 'week') {
            filters.date = 'week'
        } else if (selectedInterval === 'month') {
            filters.date = 'month';
            filters.month = selectedMonth;
        } else if (selectedInterval=== 'year') {
            filters.date = 'year';
            filters.year = selectedYear;
        } else if (selectedInterval === 'range') {
            filters.date_from = startDate;
            filters.date_to = endDate;
        }


        return filters;
    }

    const filteredDeparture = departures.filter(departure => {
        const currentBranch = selectedBranch ? departure.branch_id === selectedBranch : true;
        const currentLine = selectedLineEmergency ?
            (selectedLineEmergency === "line_emergency" ?
                departure.line_emergency === "Да" : departure.line_emergency === "Нет")
            : true;

        return currentBranch && currentLine;
    });

    useEffect(() => {

        const filters = createFilters({
            selectedInterval,
            selectedYear,
            selectedMonth,
            startDate,
            endDate
        });

        dispatch(fetchTableOperations(filters));
    }, [dispatch, selectedInterval, selectedYear, selectedMonth, startDate, endDate]);

    if (status === "loading") {
        content = <TableSkeleton/>
    } else if (status === "error") {
        content = <ErrorMessage/>
    } else if (filteredDeparture.length === 0) {
        content = <EmptyMessage/>
    } else {
        content = <OperationsMainTable departures={filteredDeparture}/>
    }  


    const handleExport = () => {

        const branchIds = {
            "2": "Актюбинский филиал",
            "3": "Атыурауский филиал",
            "4": "Восточно-Казахстанский филиал",
            "5": "Западно-Казахстанский филиал",
            "6": "Карагандинский филиал",
            "7": "Кызылординский филиал",
            "8": "Мангистаусский филиал",
            "9": "Павлодарский филиал",
            "10": "Северно-Казахстанский филиал",
            "11": "Южно-Казахстанский филиал",
            "12": "Акмолинский филиал"
        }

        const dataSet = filteredDeparture.map((departure) => ({
            '№': departure.reporting_id,
            'Дата начала': departure.arrival_date === null ? "Не заполнено" : departure.arrival_date,
            'Дата завершения': departure.finish_work_date === null ? "Не заполнено" : departure.finish_work_date,
            'Владелец объекта': departure.object_owner,
            'Наименование аварийной ситуации': departure.emergency_name,
            'Место возникновения аварийной ситуации': departure.location_accident,
            'Работало на ликвидации аварии': departure.branch_workers === null ? "Не заполнено" : departure.branch_workers,
            'Применяемое спец.оборудование, автотранспорт': departure.use_transport.length === 0 ? "Транспорт не указан" : departure.use_transport,
            'Филиалы': branchIds[departure.branch_id]
        }));

        const worksheet = XLSX.utils.json_to_sheet(dataSet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Operations");

        XLSX.writeFile(workbook, "Operations.xlsx");
    };


    return (
        <Box className={"operations_main"}>
            <Box className={"operations_main_container"}>
                <Box className={"operations_main_container_title"}>
                    <Box className={"operations_main_container_title_item"}>
                        <MainTitle initial={"Таблица оперативных выездов"}/>
                    </Box>
                    <Box className={"operations_main_container_title_divider"}>
                        <Divider/>
                    </Box>
                </Box>
                <Box className={"operations_main_container_search"}>
                    <Filters
                        branchFilter={
                            <BranchDropdown
                                initial={"Филиал"}
                                branch={selectedBranch}
                                setSelectedBranch={setSelectedBranch}
                            />
                        }
                        lineFilter={
                            <LineDropdownFilter
                                initial={"ЧС"}
                                selectedLineEmergency={selectedLineEmergency}
                                setSelectedLineEmergency={setSelectedLine}
                            />
                        }
                        intervalFilter={({setShowYear, setShowMonth, setShowRange}) => (
                            <IntervalDropdown
                                initial={"Интервал"}
                                interval={selectedInterval}
                                setFilterInterval={setSelectedInterval}
                                setShowYear={setShowYear}
                                setShowMonth={setShowMonth}
                                setShowRange={setShowRange}
                            />
                        )}
                        selectedYear={selectedYear}
                        setSelectedYear={setSelectedYear}
                        selectedMonth={selectedMonth}
                        setSelectedMonth={setSelectedMonth}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />
                    <Box className={"operations_main_container_search_button"}>
                        <ButtonExport initial={"Экспорт в Excel"} handleExport={handleExport}/>
                    </Box>
                </Box>
                <Box className={"operations_main_container_table"}>
                    {content}
                </Box>
            </Box>
        </Box>
    )
}

export default OperationsMain;