import './Filters.scss';

import { useState } from "react";

import { Box } from "@mui/material";

import { YearDropdown } from "../../../resources/Dropdowns/Dropdowns";
import { MonthDropdown } from "../../../resources/Dropdowns/Dropdowns";
import { DateFilter } from "../../../resources/Dropdowns/Dropdowns";

const Filters = ({
    branchFilter,
    incidentFilter,
    lineFilter,
    intervalFilter,
    selectedYear,
    setSelectedYear,
    selectedMonth,
    setSelectedMonth,
    startDate,
    endDate,
    setStartDate,
    setEndDate
}) => {

    const [showYear, setShowYear] = useState(false);
    const [showMonth, setShowMonth] = useState(false);
    const [showRange, setShowRange] = useState(false);

    return (
        <Box className={"filters"}>
            <Box className={"filters_container"}>
                <Box className={"filters_container_dropdowns"}>
                    {branchFilter && (
                        <Box className={"filters_container_dropdowns_item"}>
                            {branchFilter}
                        </Box>
                    )}
                    {incidentFilter && (
                        <Box className={"filters_container_dropdowns_item"}>
                            {incidentFilter}
                        </Box>
                    )}
                    {lineFilter && (
                        <Box className={"filters_container_dropdowns_item"}>
                            {lineFilter}
                        </Box>
                    )}
                    {intervalFilter && (
                        <Box className={"filters_container_dropdowns_item"}>
                            {intervalFilter({
                                setShowYear,
                                setShowMonth,
                                setShowRange
                            })}
                        </Box>
                    )}
                    {showYear && (
                        <Box className={"filters_container_dropdowns_item"}>
                            <YearDropdown
                                initial={"Год"}
                                selectedYear={selectedYear}
                                setSelectedYear={setSelectedYear}
                            />
                        </Box>
                    )}
                    {showMonth && (
                        <Box className={"filters_container_dropdowns_item"}>
                            <MonthDropdown
                                initial={"Месяц"}
                                selectedMonth={selectedMonth}
                                setSelectedMonth={setSelectedMonth}
                            />
                        </Box>
                    )}
                    {showRange && (
                        <Box className={"filters_container_dropdowns_item"}>
                            <DateFilter
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default Filters;