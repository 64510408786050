export const _transformJournal = (info) => {

    return {
        id: info.id,
        branch_id: info.branch_id,
        time_update: info.time_update,
        date_create: info.date_create,
        arrival_date: info.arrival_date,
        minor_injury: info.minor_injury,
        severe_injury: info.severe_injury,
        fatal_injury: info.fatal_injury,
        people_evacuated: info.people_evacuated,
        use_transport: info.use_transport,
        customer_technology: info.customer_technology,
        branch_workers: info.branch_workers,
        party_authority: info.party_authority,
        scope_work: info.scope_work,
        situation_filing: info.situation_filing
    }
}