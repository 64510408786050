import './CounterCards.scss';
import './media.scss';

import { Box } from "@mui/material";
import { Paper } from "@mui/material";
import { Card } from "@mui/material";
import { CardContent } from "@mui/material";

import { LocalFireDepartment } from "@mui/icons-material";
import { AssistWalker } from "@mui/icons-material";
import { FlashOff } from "@mui/icons-material";
import { Landslide } from "@mui/icons-material";
import { Support } from "@mui/icons-material";
import { Balcony } from "@mui/icons-material";
import { Masks } from "@mui/icons-material";
import { Flood } from "@mui/icons-material";
import { CarCrash } from "@mui/icons-material";
import { Propane } from "@mui/icons-material";
import { Volcano } from "@mui/icons-material";
import { FireExtinguisher } from "@mui/icons-material";
import { Search } from "@mui/icons-material";
import { Umbrella } from "@mui/icons-material";
import { Water } from "@mui/icons-material";

export const Fire = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
              sx={{
                  backgroundColor: "#FF0000",
                  color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card_content_icon"}>
                    <LocalFireDepartment fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const Injury = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
              sx={{
                  backgroundColor: "#8A2BE2",
                  color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <AssistWalker fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const PowerOutage = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
              sx={{
                  backgroundColor: "#FFC107",
                  color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <FlashOff fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const Collapse = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
              sx={{
                  backgroundColor: "#8B4513",
                  color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <Landslide fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const Accident = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
              sx={{
                  backgroundColor: "#000",
                  color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <Support fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const GettingStuck = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
              sx={{
                  backgroundColor: "#808080",
                  color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <Balcony fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const Disease = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
            sx={{
                backgroundColor: "#43A047",
                color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <Masks fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const Flooding = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
            sx={{
                backgroundColor: "#1E90FF",
                color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <Flood fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const TrafficAccident = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
            sx={{
                backgroundColor: "#FF5733",
                color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <CarCrash fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const GasPollution = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
            sx={{
                backgroundColor: "#556B2F",
                color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <Propane fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const Explosion = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
            sx={{
                backgroundColor: "#FF8C00",
                color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card_item-content_icon"}>
                    <Volcano fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const FireProtection = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card fire"}
            sx={{
                backgroundColor: "#D84315",
                color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <FireExtinguisher fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const SearchPeople = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
            sx={{
                backgroundColor: "#FF69B4",
                color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <Search fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const OutdoorFountain = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
            sx={{
                backgroundColor: "#3498DB",
                color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <Umbrella fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}

export const Bottling = ({accidentTitle, accidentCount}) => {

    return (
        <Card className={"accident_card"}
            sx={{
                backgroundColor: "#2980B9",
                color: "#fff"}}>
            <CardContent className={"accident_card-content"}>
                <Box className={"accident_card-content_icon"}>
                    <Water fontSize={"large"}/>
                </Box>
                <Box className={"accident_card-content_title"}>
                    {accidentTitle}
                </Box>
                <Box className={"accident_card-content_count"}>
                    {accidentCount}
                </Box>
            </CardContent>
        </Card>
    )
}



