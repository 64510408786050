import './Login.scss';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TextField } from "@mui/material";
import { Box } from "@mui/material";
import { Button } from "@mui/material";

import users from '../../users.json';

const Login = ({ setIsAuthenticated }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = () => {
        const user = users.find(u => u.username === username && u.password === password);
        if (user) {
            setIsAuthenticated(true);
            navigate('/Main'); // Перенаправление на главную страницу
        } else {
            setError('Неправильное имя или пароль');
            setIsAuthenticated(false);
        }
    };

    return (
        <Box className={"form"}>
            <Box className={"form_container"}>
                <h2>Вход</h2>
                <TextField
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className={"form_container_login"}
                />
                <TextField
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={"form_container_password"}
                />
                <Button variant={""} onClick={handleLogin}>Вход</Button>
                {error && <p>{error}</p>}
            </Box>
        </Box>
    );
};

export default Login;