import './ReportJournalTable.scss';

import { Paper } from "@mui/material";

import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";

const ReportJournalTable = ({data}) => {

    return (
        <TableContainer
            elevation={3}
            className={"journal_table"}
            component={Paper}>
            <Table className={"journal_table_container"}>
                <TableHead className={"journal_table_container-header"}  sx={{backgroundColor: "#1976d2"}}>
                    <TableRow className={"journal_table_container-header_row"}>
                        <TableCell sx={{color: "#fff"}} align="left">Время изменения</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Кол-во постредавших</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Эвакуировано</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Задействовано техники</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Иная техника</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Кол-во работников филиала</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Кол-во работников сторонних орг.</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Перечень и объем проведенных работ</TableCell>
                        <TableCell sx={{color: "#fff "}} align="left">Ситуация на момент подачи донесения</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={"journal_table_container_body"}>
                    {data.map((rows) => (
                        <TableRow>
                            <TableCell align="left" className={"journal_table_container_body_date"}>
                                {rows.time_update}
                            </TableCell>
                            <TableCell align="left" className={"journal_table_container_body_injury"}>
                                {
                                    `Легкая травма: ${rows.minor_injury === null ? '0' : rows.minor_injury} , 
                                    Тяжелая травма: ${rows.severe_injury === null ? '0' : rows.severe_injury},  
                                    Смертельная травма: ${rows.fatal_injury === null ? '0' : rows.fatal_injury}`
                                }
                            </TableCell>
                            <TableCell align="left" className={"journal_table_container_body_people"}>
                                {rows.people_evacuated === null ? '0' : rows.people_evacuated}
                            </TableCell>
                            <TableCell align="left" className={"journal_table_container_body_transport"}>
                                {rows.use_transport.length === 0 ? 'Транспорт не указан' : rows.use_transport}
                            </TableCell>
                            <TableCell align="left" className={"journal_table_container_body_customer"}>
                                {rows.customer_technology === null ? '0' : rows.customer_technology}
                            </TableCell>
                            <TableCell align="left" className={"journal_table_container_body_workers"}>
                                {rows.branch_workers === null ? '0' : rows.branch_workers}
                            </TableCell>
                            <TableCell align="left" className={"journal_table_container_body_authority"}>
                                {rows.party_authority === null ? '0' : rows.party_authority}
                            </TableCell>
                            <TableCell align="left" className={"journal_table_container_body_work"}>
                                {rows.scope_work === null ? 'Не заполнено' : rows.scope_work}
                            </TableCell>
                            <TableCell align="left" className={"journal_table_container_body_filling"}>
                                {rows.situation_filing === null ? 'Не заполнено' : rows.situation_filing}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ReportJournalTable;