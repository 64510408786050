import './ReportJournalPage.scss';

import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import ReportJournal from "../../components/ReportJournal/ReportJournal";
import Navigation from "../../components/Widgets/Navigation/Navigation";
import Header from "../../components/Widgets/Header/Header";

import { ButtonBack } from "../../resources/Buttons/Buttons";
import { MainBranch } from "../../resources/Branch/Branch";

const ReportJournalPage = () => {
    const { reportId } = useParams();

    return (
        <Box className={"report_journal_page"}>
            <Header/>
            <Box className={"report_journal_page_container"}>
                <Box className={"report_journal_page_container-widget"}>
                    <Navigation
                        branch={
                            <MainBranch/>
                        }
                        backLink={
                            <Link to={`../pages/ReportDetailPage/${reportId}`}>
                                <ButtonBack initial={"Назад"}/>
                            </Link>
                        }
                    />
                </Box>
                <Box className={"report_journal_page_container-component"}>
                    <ReportJournal reportId={reportId}/>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportJournalPage;