 import './ReportSendTable.scss';

import { Paper } from "@mui/material";

import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";

import { Success, Danger } from "../../../resources/Status/Status";


const createData =
    ( number,
      customer,
      place,
      date,
      type,
      status
    ) => {

    return {
        number,
        customer,
        place,
        date,
        type,
        status
    }
};

const rows = [
    createData(
        "1.2",
        "Мастер бригады КРС М-Техсервис",
        "Месторождение Кенкияк надсоль, скважина №68587",
        "14.05.2023 15:54:00",
        "Открытый фонтан",
        "success"
    ),
    createData(
        "1.2",
        "Мастер бригады КРС М-Техсервис",
        "Месторождение Кенкияк надсоль, скважина №68587",
        "14.05.2023 15:54:00",
        "Открытый фонтан",
        "danger"
    ),
    createData(
        "1.2",
        "Мастер бригады КРС М-Техсервис",
        "Месторождение Кенкияк надсоль, скважина №68587",
        "14.05.2023 15:54:00",
        "Открытый фонтан",
        "success"
    ),
    createData(
        "1.2",
        "Мастер бригады КРС М-Техсервис",
        "Месторождение Кенкияк надсоль, скважина №68587",
        "14.05.2023 15:54:00",
        "Открытый фонтан",
        "success"
    ),
    createData(
        "1.2",
        "Мастер бригады КРС М-Техсервис",
        "Месторождение Кенкияк надсоль, скважина №68587",
        "14.05.2023 15:54:00",
        "Открытый фонтан",
        "danger"
    ),
    createData(
        "1.2",
        "Мастер бригады КРС М-Техсервис",
        "Месторождение Кенкияк надсоль, скважина №68587",
        "14.05.2023 15:54:00",
        "Открытый фонтан",
        "success"
    ),
    createData(
        "1.2",
        "Мастер бригады КРС М-Техсервис",
        "Месторождение Кенкияк надсоль, скважина №68587",
        "14.05.2023 15:54:00",
        "Открытый фонтан",
        "danger"
    ),
    createData(
        "1.2",
        "Мастер бригады КРС М-Техсервис",
        "Месторождение Кенкияк надсоль, скважина №68587",
        "14.05.2023 15:54:00",
        "Открытый фонтан",
        "success"
    ),
];
const ReportSendTable = () => {

    return (
        <TableContainer
            elevation={3}
            component={Paper}
            className={"table_send"}
        >
            <Table className={"table_send_container"}>
                <TableHead className={"table_send_container-header"}>
                    <TableRow className={"table_send_container-header_row"}>
                        <TableCell align="left">Номер</TableCell>
                        <TableCell align="left">Заказчик</TableCell>
                        <TableCell align="left">Объект</TableCell>
                        <TableCell align="center">Дата</TableCell>
                        <TableCell align="center">Тип аварии</TableCell>
                        <TableCell align="center">Статус</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={"table_send_container-body"}>
                    {rows.map((rows) => (

                        <TableRow
                            key={rows.name}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 }
                            }}
                        >
                            <TableCell sx={{width: "100px"}} align="left">{rows.number}</TableCell>
                            <TableCell sx={{width: "300px"}} align="left">{rows.customer}</TableCell>
                            <TableCell sx={{width: "300px"}} align="left">{rows.place}</TableCell>
                            <TableCell sx={{width: "200px"}} align="center">{rows.date}</TableCell>
                            <TableCell sx={{width: "200px"}} align="center">{rows.type}</TableCell>
                            <TableCell sx={{width: "200px"}} align="center">
                                {rows.status === "success" ? <Success/> : <Danger/>}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ReportSendTable;