export const _transformIncidents = {
    "01": "Пожар",
    "02": "Травма",
    "03": "Отключение электричества",
    "04": "Обрушение",
    "05": "Несчастный случай",
    "06": "Застревание",
    "07": "Заболевание",
    "08": "Затовление",
    "09": "ДТП",
    "10": "Загазованность",
    "11": "Взрыв",
    "12": "Обеспечение противопожарной",
    "13": "Поиск людей",
    "14": "Открытый фонтан",
    "15": "Розлив",
};