import './ReportDetailFirst.scss';

import { Box } from "@mui/material";

import { SecondTitle } from "../../../resources/Titles/Titles";
import { ChildTitle } from "../../../resources/Titles/Titles";

const ReportDetailFirst = (
    {
        branch_id,
        reporting_id,
        who_call,
        location_accident,
        reporting_date,
        arrival_date,
        finish_work_date,
        object_data,
        call_date,
        emergency_name,
        object_owner
    }) => {

    return (
        <Box className={"report_detail_first"}>
            <Box className={"report_detail_first_container"}>
                <Box className={"report_detail_first_container_row"}>
                    <Box className={"report_detail_first_container_row_title"}>
                        <SecondTitle initial={"1. Филиал: "}/>
                    </Box>
                    <Box className={"report_detail_first_container_row_description"}>
                        <ChildTitle initial={branch_id}/>
                    </Box>
                </Box>
                <Box className={"report_detail_first_container_row"}>
                    <Box className={"report_detail_first_container_row_title"}>
                        <SecondTitle initial={"2. Номер донесения: "}/>
                    </Box>
                    <Box className={"report_detail_first_container_row_description"}>
                        <ChildTitle initial={reporting_id}/>
                    </Box>
                </Box>
                <Box className={"report_detail_first_container_row"}>
                    <Box className={"report_detail_first_container_row_title"}>
                        <SecondTitle initial={"3. Дата донесения: "}/>
                    </Box>
                    <Box className={"report_detail_first_container_row_description"}>
                        <ChildTitle initial={reporting_date}/>
                    </Box>
                </Box>
                <Box className={"report_detail_first_container_row"}>
                    <Box className={"report_detail_first_container_row_title"}>
                        <SecondTitle initial={"4. Дата и время вызова: "}/>
                    </Box>
                    <Box className={"report_detail_first_container_row_description"}>
                        <ChildTitle initial={call_date}/>
                    </Box>
                </Box>
                <Box className={"report_detail_first_container_row"}>
                    <Box className={"report_detail_first_container_row_title"}>
                        <SecondTitle initial={"5. Кто произвел вызов: "}/>
                    </Box>
                    <Box className={"report_detail_first_container_row_description"}>
                        <ChildTitle initial={who_call}/>
                    </Box>
                </Box>
                <Box className={"report_detail_first_container_row"}>
                    <Box className={"report_detail_first_container_row_title"}>
                        <SecondTitle initial={"6. Дата и время прибытия на объект: "}/>
                    </Box>
                    <Box className={"report_detail_first_container_row_description"}>
                        <ChildTitle initial={arrival_date}/>
                    </Box>
                </Box>
                <Box className={"report_detail_first_container_row"}>
                    <Box className={"report_detail_first_container_row_title"}>
                        <SecondTitle initial={"7. Дата и время окончания работ/ликвидации аварии: "}/>
                    </Box>
                    <Box className={"report_detail_first_container_row_description"}>
                        <ChildTitle initial={finish_work_date}/>
                    </Box>
                </Box>
                <Box className={"report_detail_first_container_row"}>
                    <Box className={"report_detail_first_container_row_title"}>
                        <SecondTitle initial={"7. Наименование аварийной ситуации: "}/>
                    </Box>
                    <Box className={"report_detail_first_container_row_description"}>
                        <ChildTitle initial={emergency_name}/>
                    </Box>
                </Box>
                <Box className={"report_detail_first_container_row"}>
                    <Box className={"report_detail_first_container_row_title"}>
                        <SecondTitle initial={"8. Владелец объекта: "}/>
                    </Box>
                    <Box className={"report_detail_first_container_row_description"}>
                        <ChildTitle initial={object_owner}/>
                    </Box>
                </Box>
                <Box className={"report_detail_first_container_row"}>
                    <Box className={"report_detail_first_container_row_title"}>
                        <SecondTitle initial={"9. Место возникновения аварийной ситуации: "}/>
                    </Box>
                    <Box className={"report_detail_first_container_row_description"}>
                        <ChildTitle initial={location_accident}/>
                    </Box>
                </Box>
                <Box className={"report_detail_first_container_row"}>
                    <Box className={"report_detail_first_container_row_title"}>
                        <SecondTitle initial={"10. Данные по аварии: "}/>
                    </Box>
                    <Box className={"report_detail_first_container_row_description"}>
                        <ChildTitle initial={object_data}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportDetailFirst;