import { Box } from "@mui/material";
import { Chip } from "@mui/material";


export const AktobeBranch = () => {

    return (
        <Box className={"branch"}>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Актюбинский"
                sx={{
                    width: "200px",
                    backgroundColor: "#ec407a",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const AtyrauBranch = () => {

    return (
        <Box className={"branch"}>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Атырауский"
                sx={{
                    width: "200px",
                    backgroundColor: "#ff9800",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const EastKazakhstanBranch = () => {

    return (
        <Box className={"branch"}>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Восточно-Казахстанский"
                sx={{
                    width: "200px",
                    backgroundColor: "#ffc107",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const WestKazakhstanBranch = () => {

    return (
        <Box className={"branch"}>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Западно-Казахстанский"
                sx={{
                    width: "200px",
                    backgroundColor: "#4caf50",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const KaragandaBranch = () => {

    return (
        <Box className={"branch"}>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Карагандинский"
                sx={{
                    width: "200px",
                    backgroundColor: "#9c27b0",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const KyzylordaBranch = () => {

    return (
        <Box className={"branch"}>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Кызылординский"
                sx={{
                    width: "200px",
                    backgroundColor: "#ff5722",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const MangystauBranch = () => {

    return (
        <Box className={"branch"}>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Мангистаусский"
                sx={{
                    width: "200px",
                    backgroundColor: "#00bcd4",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const PavlodarBranch = () => {

    return (
        <Box className={"branch"}>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Павлодарский"
                sx={{
                    width: "200px",
                    backgroundColor: "#03a9f4",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const NorthKazakhstanBranch = () => {

    return (
        <Box className={"branch"}>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Северно-Казахстанский"
                sx={{
                    width: "200px",
                    backgroundColor: "#3f51b5",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const SouthKazakhstanBranch = () => {

    return (
        <Box className={"branch"}>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Южно-Казахстанский"
                sx={{
                    width: "200px",
                    backgroundColor: "#f44336",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const AkmolaBranch = () => {

    return (
        <Box className={"branch"}>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Акмолинский"
                sx={{
                    width: "200px",
                    backgroundColor: "#009688",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const MainBranch = () => {

    return (
        <Box>
            <Chip
                className={"branch_item"}
                variant="contained"
                label="Центральный аппарат "
                sx={{
                    width: "200px",
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}