import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "../api/api";

import { _transformDetail } from "../services/_transformDetail";

const initialState = {
    data: {},
    detailLoadingStatus: 'idle',
}

export const fetchReportDetail = createAsyncThunk(
    'reportDetail/fetchReportDetail',
    async(reportId) => {
        const response = await request(
            'https://b24.centrspas.kz/api/departures.detail',
            'POST',
            {id: reportId}
        );
        return response.data;
    }
)

const reportDetailSlice = createSlice({
    name: 'reportDetail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchReportDetail.pending, (state) => {
                state.detailLoadingStatus = 'loading';
            })
            .addCase(fetchReportDetail.fulfilled, (state, action) => {
                state.data = action.payload;
                state.detailLoadingStatus = 'idle';
            })
            .addCase(fetchReportDetail.rejected, (state, action) => {
                state.detailLoadingStatus = 'error';
            });
    }
});

export default reportDetailSlice.reducer;

