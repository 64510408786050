import './ReportDetailPage.scss';

import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import Navigation from "../../components/Widgets/Navigation/Navigation";
import ReportDetail from "../../components/ReportDetail/ReportDetail";
import Header from "../../components/Widgets/Header/Header";

import { ButtonBack } from "../../resources/Buttons/Buttons";
import { ButtonJournal } from "../../resources/Buttons/Buttons";
import { ButtonPrint } from "../../resources/Buttons/Buttons";
import { ButtonUpdate } from "../../resources/Buttons/Buttons";

import { MainBranch } from "../../resources/Branch/Branch";


const ReportDetailPage = () => {
    const { reportId } = useParams();

    return (
        <Box className={"report_detail_page"}>
            <Header/>
            <Box className={"report_detail_page_container"}>
                <Box className={"report_detail_page_container-widget"}>
                    <Navigation
                        branch={
                            <MainBranch/>
                        }
                        backLink={
                            <Link to="../Pages/ReportMainPage">
                                <ButtonBack
                                    initial={"Назад"}
                                />
                            </Link>
                        }
                        journalLink={
                            <Link to={`../pages/ReportJournalPage/${reportId}`}>
                                <ButtonJournal
                                    initial={"Журнал"}
                                />
                            </Link>
                        }
                        printLink={
                            <ButtonPrint
                                initial={"Печать"}
                            />
                        }
                        updateLink={
                            <ButtonUpdate
                                initial={"Обновить донесение"}
                            />
                        }
                    />
                </Box>
                <Box className={"report_detail_page_container-content"}>
                    <ReportDetail reportId={reportId}/>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportDetailPage;