import './ReportModalMain.scss';

import { useState } from "react";

import { Box } from "@mui/material";
import { Paper } from "@mui/material";
import { Divider } from "@mui/material";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { ru } from "date-fns/locale";

import { MainTitle, SecondTitle } from "../../../resources/Titles/Titles";
import { AccidentDropdown, LineDropdown } from "../../../resources/Dropdowns/Dropdowns";


const ReportModalMain = () => {

    const [selectedDate, setSelectedDate] =  useState(null)

    return (
        <Paper elevation={3}
               className={"report_modal_main"}
               sx={{borderRadius: 3}}>
            <Box className={"report_modal_main_container"}>
                <Box className={"report_modal_main_container_title"}>
                    <MainTitle initial={"Основные данные"}/>
                    <Divider/>
                </Box>
                <Box className={"report_modal_main_container_input"}>
                    <Box className={"report_modal_main_container_input-title"}>
                        <SecondTitle initial={"Дата донесения"}/>
                    </Box>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                    >
                        <DesktopDateTimePicker
                            label="Выберите дату"
                            value={selectedDate}
                            onChange={(newValue) => {
                            setSelectedDate(newValue)
                            }}
                            renderInput={(params) => <TextField {...params}/>}
                            className={"report_modal_main_container_input-item"}
                        >
                        </DesktopDateTimePicker>
                    </LocalizationProvider>
                </Box>
                <Box className={"report_modal_main_container_input"}>
                    <Box className={"report_modal_main_container_input-title"}>
                        <SecondTitle initial={"Дата и время вызова"}/>
                    </Box>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                    >
                        <DesktopDateTimePicker
                            label="Выберите дату"
                            value={selectedDate}
                            onChange={(newValue) => {
                                setSelectedDate(newValue)
                            }}
                            renderInput={(params) => <TextField {...params}/>}
                            className={"report_modal_main_container_input-item"}
                        >
                        </DesktopDateTimePicker>
                    </LocalizationProvider>
                </Box>
                <Box className={"report_modal_main_container_input"}>
                    <Box className={"report_modal_main_container_input-title"}>
                        <SecondTitle initial={"Кто произвел вызов"}/>
                        <span>*</span>
                    </Box>
                    <TextField
                        required
                        id="outlined-requared"
                        className={"report_modal_main_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_main_container_input"}>
                    <Box className={"report_modal_main_container_input-title"}>
                        <SecondTitle initial={"Дата и время прибытия на объект"}/>
                    </Box>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                    >
                        <DesktopDateTimePicker
                            label="Выберите дату"
                            value={selectedDate}
                            onChange={(newValue) => {
                                setSelectedDate(newValue)
                            }}
                            renderInput={(params) => <TextField {...params}/>}
                            className={"report_modal_main_container_input-item"}
                        >
                        </DesktopDateTimePicker>
                    </LocalizationProvider>
                </Box>
                <Box className={"report_modal_main_container_input"}>
                    <Box className={"report_modal_main_container_input-title"}>
                        <SecondTitle initial={"Дата и время окончения работ/ликвидации аварии"}/>
                    </Box>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={ru}
                    >
                        <DesktopDateTimePicker
                            label="Выберите дату в случае завершения ликвидации аварии"
                            value={selectedDate}
                            onChange={(newValue) => {
                                setSelectedDate(newValue)
                            }}
                            renderInput={(params) => <TextField {...params}/>}
                            className={"report_modal_main_container_input-item"}
                        >
                        </DesktopDateTimePicker>
                    </LocalizationProvider>
                </Box>
                <Box className={"report_modal_main_container_input"}>
                    <Box className={"report_modal_main_container_input-title"}>
                        <SecondTitle initial={"Наименование ЧС"}/>
                        <span>*</span>
                    </Box>
                    <Box className={"report_modal_main_container_input-item"}>
                        <AccidentDropdown initial={"Наименование ЧС"}/>
                    </Box>
                </Box>
                <Box className={"report_modal_main_container_input"}>
                    <Box className={"report_modal_main_container_input-title"}>
                        <SecondTitle initial={"По линии ЧС"}/>
                    </Box>
                    <Box className={"report_modal_main_container_input-item"}>
                        <LineDropdown initial={"По линии ЧС"}/>
                    </Box>
                </Box>
                <Box className={"report_modal_main_container_input"}>
                    <Box className={"report_modal_main_container_input-title"}>
                        <SecondTitle initial={"Место возникновения ЧС"}/>
                        <span>*</span>
                    </Box>
                    <TextField
                        required
                        id="outlined-requared"
                        className={"report_modal_main_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_main_container_input"}>
                    <Box className={"report_modal_main_container_input-title"}>
                        <SecondTitle initial={"Данные по аварии "}/>
                    </Box>
                    <TextField
                        required
                        id="outlined-requared"
                        className={"report_modal_main_container_input-item"}
                    />
                </Box>
            </Box>
        </Paper>
    )
}

export default ReportModalMain;