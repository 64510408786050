import { Box } from "@mui/material";
import { Chip } from "@mui/material";

export const Success = () => {

    return (
        <Box>
            <Chip
                variant="contained"
                label="Ликвидировано"
                sx={{
                    width: "200px",
                    backgroundColor: "#2e7d32",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}

export const Danger = () => {

    return (
        <Box>
            <Chip
                variant="contained"
                label="В процессе"
                sx={{
                    width: "200px",
                    backgroundColor: "#d32f2f",
                    color: "#fff",
                    fontSize: "14px"
                }}
            />
        </Box>
    )
}