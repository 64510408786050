import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createEntityAdapter } from "@reduxjs/toolkit";

import { request } from "../api/api";

import { _transformDeparture } from "../services/_transformDeparture";

const tableMainAdapter = createEntityAdapter();

const initialState = tableMainAdapter.getInitialState({
    departures: [],
    selectedBranch: '',
    searchData: '',
    selectedInterval: '',
    selectedWeek: '',
    selectedYear: '',
    selectedMonth: '',
    startDate: '',
    endDate: '',
    selectedLineEmergency: '',
    tableMainLoadingStatus: 'idle'
});

export const fetchTableMain = createAsyncThunk(
    'tableMain/fetchTableMain',
    async(filters) => {
        const body = {
            offset: 0,
            filter: filters
        }

        const data = await request('https://b24.centrspas.kz/api/departures.list/', 'POST', body)
        return data.departures.map(_transformDeparture);
    }
);

const tableMainSlice = createSlice({
    name: 'tableMain',
    initialState,
    reducers: {
        setSelectedInterval: (state, action) => {
            state.selectedInterval = action.payload
        },
        setSelectedYear: (state, action) => {
            state.selectedYear = action.payload
        },
        setSelectedMonth: (state, action) => {
            state.selectedMonth = action.payload
        },
        setSelectedWeek: (state, action) => {
            state.selectedWeek = action.payload
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload
        },
        setSelectedLineEmergency: (state, action) => {
          state.selectedLineEmergency = action.payload;
        },
        setSelectedBranch: (state, action) => {
            state.selectedBranch = action.payload
        },
        setSearchData: (state, action) => {
            state.searchData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTableMain.pending, (state) => {
                state.tableMainLoadingStatus = 'loading';
            })
            .addCase(fetchTableMain.fulfilled, (state, action) => {
                state.departures = action.payload;
                state.tableMainLoadingStatus = 'idle';
            })
            .addCase(fetchTableMain.rejected, (state) => {
                state.tableMainLoadingStatus = 'error';
            });
    }
});

export const {
    setSelectedBranch,
    setSelectedInterval,
    setSelectedWeek,
    setSelectedYear,
    setSelectedMonth,
    setStartDate,
    setEndDate,
    setSearchData,
    setSelectedLineEmergency
} = tableMainSlice.actions;
export default tableMainSlice.reducer;