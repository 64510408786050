import './Dropdowns.scss'

import { useDispatch } from "react-redux";

import { parseISO } from "date-fns";
import { isValid } from "date-fns";

import { Box } from "@mui/material";
import { InputLabel } from "@mui/material";
import { MenuItem } from "@mui/material";
import { TextField } from "@mui/material";
import { FormControl } from "@mui/material";
import { Select } from "@mui/material";

export const BranchDropdown = ({initial, branch, setSelectedBranch}) => {
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const branchValue = event.target.value;
        dispatch(setSelectedBranch(branchValue));
    };

    return (
        <Box>
            <FormControl className={"form-control_branch"}>
                <InputLabel id="demo-simple-select-autowidth-label">{initial}</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={branch}
                    onChange={handleChange}
                    autoWidth
                    label="Филиал"
                >
                    <MenuItem value="">Все филиалы</MenuItem>
                    <MenuItem value={"2"}>Актюбинский</MenuItem>
                    <MenuItem value={"3"}>Атырауский</MenuItem>
                    <MenuItem value={"4"}>Восточно-Казахстанкий</MenuItem>
                    <MenuItem value={"5"}>Западно-Казахстанский</MenuItem>
                    <MenuItem value={"6"}>Карагандинкский</MenuItem>
                    <MenuItem value={"7"}>Кызылординский</MenuItem>
                    <MenuItem value={"8"}>Мангистауский</MenuItem>
                    <MenuItem value={"9"}>Павлодарский</MenuItem>
                    <MenuItem value={"10"}>Северо-Казахстанский</MenuItem>
                    <MenuItem value={"11"}>Южно-Казахстанский</MenuItem>
                    <MenuItem value={"12"}>Акмолинский</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export const YearDropdown = ({initial, selectedYear, setSelectedYear}) => {
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const value = event.target.value;
        dispatch(setSelectedYear(value));
    };

    return (
        <Box>
            <FormControl className={"form-control_line_filter"}>
                <InputLabel id="demo-simple-select-autowidth-label">{initial}</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={selectedYear}
                    onChange={handleChange}
                    autoWidth
                    label="ЧС"
                >
                    <MenuItem value="">Выберите год</MenuItem>
                    <MenuItem key={selectedYear} value={"2024"}>2024</MenuItem>
                    <MenuItem key={selectedYear} value={"2025"}>2025</MenuItem>
                    <MenuItem key={selectedYear} value={"2026"}>2026</MenuItem>
                    <MenuItem key={selectedYear} value={"2027"}>2027</MenuItem>
                    <MenuItem key={selectedYear} value={"2028"}>2028</MenuItem>
                    <MenuItem key={selectedYear} value={"2029"}>2029</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}

export const MonthDropdown = ({initial, selectedMonth, setSelectedMonth}) => {
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const value = event.target.value;
        dispatch(setSelectedMonth(value));
    };

    return (
        <Box>
            <FormControl className={"form-control_line_filter"}>
                <InputLabel id="demo-simple-select-autowidth-label">{initial}</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={selectedMonth}
                    onChange={handleChange}
                    autoWidth
                    label="ЧС"
                >
                    <MenuItem value="">Выберите месяц</MenuItem>
                    <MenuItem value="01">Январь</MenuItem>
                    <MenuItem value="02">Февраль</MenuItem>
                    <MenuItem value="03">Март</MenuItem>
                    <MenuItem value="04">Апрель</MenuItem>
                    <MenuItem value="05">Май</MenuItem>
                    <MenuItem value="06">Июнь</MenuItem>
                    <MenuItem value="07">Июль</MenuItem>
                    <MenuItem value="08">Август</MenuItem>
                    <MenuItem value="09">Сентябрь</MenuItem>
                    <MenuItem value="10">Октябрь</MenuItem>
                    <MenuItem value="11">Ноябрь</MenuItem>
                    <MenuItem value="12">Декабрь</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}

export const IntervalDropdown = ({
    initial,
    interval,
    setFilterInterval,
    setShowYear,
    setShowMonth,
    setShowRange
}) => {

    const dispatch = useDispatch();

    const handleChange = (event) => {
        const value = event.target.value;
        dispatch(setFilterInterval(value));

        if (value === 'year') {
            setShowYear(true);
            setShowMonth(false);
            setShowRange(false);
        } else if (value === 'month') {
            setShowYear(false);
            setShowMonth(true);
            setShowRange(false);
        } else if (value === 'range') {
            setShowYear(false);
            setShowMonth(false);
            setShowRange(true);
        } else {
            setShowYear(false);
            setShowMonth(false);
            setShowRange(false);
        }

    };

    return (
        <Box>
            <FormControl className={"form-control_interval"}>
                <InputLabel id="demo-simple-select-autowidth-label">{initial}</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    value={interval}
                    id="demo-simple-select-autowidth"
                    onChange={handleChange}
                    autoWidth
                    label="Филиал"
                >
                    <MenuItem>Выберите интервал</MenuItem>
                    <MenuItem value={"range"}>Диапозон</MenuItem>
                    <MenuItem value={"week"}>За 7 дней</MenuItem>
                    <MenuItem value={"month"}>За месяц</MenuItem>
                    <MenuItem value={"year"}>За год</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export const AccidentDropdown = ({initial, selectedIncident, setSelectedIncident}) => {
    const dispatch = useDispatch();
    const handleChange = (event) => {
        const incident = event.target.value;
        dispatch(setSelectedIncident(incident));
    };

    return (
        <Box>
            <FormControl className={"form-control_accident"}>
                <InputLabel id="demo-simple-select-autowidth-label">{initial}</InputLabel>
                <Select
                    value={selectedIncident}
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    onChange={handleChange}
                    autoWidth
                    label="Филиал"
                >
                    <MenuItem value="">Все события</MenuItem>
                    <MenuItem value={"01"}>Пожар</MenuItem>
                    <MenuItem value={"02"}>Травма</MenuItem>
                    <MenuItem value={"03"}>Отключение электричества</MenuItem>
                    <MenuItem value={"04"}>Обрушение</MenuItem>
                    <MenuItem value={"05"}>Несчастный случай</MenuItem>
                    <MenuItem value={"06"}>Застревание</MenuItem>
                    <MenuItem value={"07"}>Заболевание</MenuItem>
                    <MenuItem value={"08"}>Затопление</MenuItem>
                    <MenuItem value={"09"}>ДТП</MenuItem>
                    <MenuItem value={"10"}>Загазованность</MenuItem>
                    <MenuItem value={"11"}>Взрыв</MenuItem>
                    <MenuItem value={"12"}>Обеспечение противопожарной</MenuItem>
                    <MenuItem value={"13"}>Поиск людей</MenuItem>
                    <MenuItem value={"14"}>Открытый фонтан</MenuItem>
                    <MenuItem value={"15"}>Розлив</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}

export const LineDropdown = ({initial}) => {

    const handleChange = (event) => {
    };

    return (
        <Box>
            <FormControl className={"form-control_line"}>
                <InputLabel id="demo-simple-select-autowidth-label">{initial}</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    onChange={handleChange}
                    autoWidth
                    label="Филиал"
                >
                    <MenuItem value="">
                        <em>По линии ЧС</em>
                    </MenuItem>
                    <MenuItem value={1}>Да</MenuItem>
                    <MenuItem value={2}>Нет</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}

export const LineDropdownFilter = ({initial, selectedLineEmergency, setSelectedLineEmergency}) => {
    const dispatch = useDispatch();

    const handleChange = (event) => {
        const value = event.target.value;
        dispatch(setSelectedLineEmergency(value));
    };

    return (
        <Box>
            <FormControl className={"form-control_line_filter"}>
                <InputLabel id="demo-simple-select-autowidth-label">{initial}</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={selectedLineEmergency}
                    onChange={handleChange}
                    autoWidth
                    label="ЧС"
                >
                    <MenuItem value="">
                        Все события
                    </MenuItem>
                    <MenuItem value="line_emergency">По линии ЧС</MenuItem>
                    <MenuItem value="agreement">По договору</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}


export const DateFilter = ({startDate, setStartDate, endDate, setEndDate}) => {
    const dispatch = useDispatch();

    const handleStartDateChange = (event) => {
        const value = event.target.value;
        dispatch(setStartDate(value));
    };

    const handleEndDateChange = (event) => {
        const value = event.target.value;
        dispatch(setEndDate(value));
    };

    const isValidDate = (dateStr) => {
        return isValid(parseISO(dateStr));
    };

    return (
        <Box className={"interval_filter"}>
            <Box className={"interval_filter_container"}>
                <TextField
                    label={"Начальная дата"}
                    sx={{marginRight: "10px"}}
                    type={"date"}
                    value={startDate}
                    onChange={handleStartDateChange}
                    InputLabelProps={{shrink: true}}
                    error={startDate && !isValidDate(startDate)}
                    helperText={startDate && !isValidDate(startDate) ? 'Неправильно введеная дата' : ''}
                />
                <TextField
                    label={"Конечная дата"}
                    type={"date"}
                    value={endDate}
                    onChange={handleEndDateChange}
                    InputLabelProps={{shrink: true}}
                    error={endDate && !isValidDate(endDate)}
                    helperText={endDate && !isValidDate(endDate) ? 'Неправильно введенная дата' : ''}
                />
            </Box>
        </Box>
    )
}