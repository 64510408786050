import './ReportModalSecond.scss';

import { Box } from "@mui/material";
import { Paper } from "@mui/material";
import { Divider } from "@mui/material";
import { TextField } from "@mui/material";

import { MainTitle, SecondTitle } from "../../../resources/Titles/Titles";

const ReportModalSecond = () => {

    return (
        <Paper
            elevation={3}
            className={"report_modal_second"}
            sx={{borderRadius: 3}}>
            <Box className={"report_modal_second_container"}>
                <Box className={"report_modal_second_container_title"}>
                    <MainTitle initial={"Дополнительные данные"}/>
                    <Divider/>
                </Box>
                <Box className={"report_modal_second_container_input"}>
                    <Box className={"report_modal_second_container_input-title"}>
                        <SecondTitle initial={"Легкая травма"}/>
                    </Box>
                    <TextField
                        required
                        type="number"
                        inputProps={{min: 0}}
                        id="outlined-requared"
                        className={"report_modal_second_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_second_container_input"}>
                    <Box className={"report_modal_second_container_input-title"}>
                        <SecondTitle initial={"Тяжелая травма"}/>
                    </Box>
                    <TextField
                        required
                        type="number"
                        inputProps={{min: 0}}
                        id="outlined-requared"
                        className={"report_modal_second_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_second_container_input"}>
                    <Box className={"report_modal_second_container_input-title"}>
                        <SecondTitle initial={"Смертельная травма"}/>
                    </Box>
                    <TextField
                        required
                        type="number"
                        inputProps={{min: 0}}
                        id="outlined-requared"
                        className={"report_modal_second_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_second_container_input"}>
                    <Box className={"report_modal_second_container_input-title"}>
                        <SecondTitle initial={"Эвакуировано"}/>
                    </Box>
                    <TextField
                        required
                        type="number"
                        inputProps={{min: 0}}
                        id="outlined-requared"
                        className={"report_modal_second_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_second_container_input"}>
                    <Box className={"report_modal_second_container_input-title"}>
                        <SecondTitle initial={"Оказана медицинская помощь"}/>
                    </Box>
                    <TextField
                        required
                        type="number"
                        inputProps={{min: 0}}
                        id="outlined-requared"
                        className={"report_modal_second_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_second_container_input"}>
                    <Box className={"report_modal_second_container_input-title"}>
                        <SecondTitle initial={"Перечень и объем проведенных работ"}/>
                        <span>*</span>
                    </Box>
                    <TextField
                        required
                        multiline
                        inputProps={{
                            style: {
                                height: "200px"
                            }
                        }}
                        id="outlined-requared"
                        className={"report_modal_second_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_second_container_input"}>
                    <Box className={"report_modal_second_container_input-title"}>
                        <SecondTitle initial={"Ситуация на момент подачи донесения"}/>
                        <span>*</span>
                    </Box>
                    <TextField
                        required
                        multiline
                        inputProps={{
                            style: {
                                height: "200px"
                            }
                        }}
                        id="outlined-requared"
                        className={"report_modal_second_container_input-item"}
                    />
                </Box>
            </Box>
        </Paper>
    )
}

export default ReportModalSecond;