import './Skeleton.scss';

import { Box } from "@mui/material";
import { Skeleton } from "@mui/material";

export const TableSkeleton = () => {

    return (
        <Box className={"table_skeleton"}>
            <Box className={"table_skeleton_container"}>
                <Box className={"table_skeleton_container-header"}>
                    <Skeleton variant="rectangular" animation="wave" width={"100%"} height={60}/>
                </Box>
                <Box className={"table_skeleton_container-rows"}>
                    <Skeleton variant="rectangular" animation="wave" width={"100%"} height={50}/>
                </Box>
                <Box className={"table_skeleton_container-rows"}>
                    <Skeleton variant="rectangular" animation="wave" width={"100%"} height={50}/>
                </Box>
                <Box className={"table_skeleton_container-rows"}>
                    <Skeleton variant="rectangular" animation="wave" width={"100%"} height={50}/>
                </Box>
                <Box className={"table_skeleton_container-rows"}>
                    <Skeleton variant="rectangular" animation="wave" width={"100%"} height={50}/>
                </Box>
                <Box className={"table_skeleton_container-rows"}>
                    <Skeleton variant="rectangular" animation="wave" width={"100%"} height={50}/>
                </Box>
                <Box className={"table_skeleton_container-rows"}>
                    <Skeleton variant="rectangular" animation="wave" width={"100%"} height={50}/>
                </Box>
                <Box className={"table_skeleton_container-rows"}>
                    <Skeleton variant="rectangular" animation="wave" width={"100%"} height={50}/>
                </Box>
                <Box className={"table_skeleton_container-rows"}>
                    <Skeleton variant="rectangular" animation="wave" width={"100%"} height={50}/>
                </Box>
                <Box className={"table_skeleton_container-rows"}>
                    <Skeleton variant="rectangular" animation="wave" width={"100%"} height={50}/>
                </Box>

            </Box>
        </Box>
    )
}
