import './DashboardCounter.scss';
import './media.scss';

import { useEffect } from "react";

import { Box } from "@mui/material";
import { Paper } from "@mui/material";
import { Divider } from "@mui/material";

import { MainTitle } from "../../resources/Titles/Titles";

import CounterWrapper from "./CounterWrapper/CounterWrapper";
import CounterDropdowns from "./CounterDropdowns/CounterDropdowns";
const DashboardCounter = () => {

    return (
        <Box className={"accident_counter"}>
            <Box className={"accident_counter-container"}>
                <Box className={"accident_counter-container_cards"}>
                    <CounterWrapper/>
                </Box>
            </Box>
        </Box>
    )
}

export default DashboardCounter;