import './ReportSend.scss';

import { Link } from "react-router-dom";

import { Box } from "@mui/material";
import { Paper } from "@mui/material";
import { Divider } from "@mui/material";

import { IntervalDropdown } from "../../resources/Dropdowns/Dropdowns";
import { MainTitle, ChildTitle } from "../../resources/Titles/Titles";
import { ButtonSend } from "../../resources/Buttons/Buttons";
import { ButtonShowMore } from "../../resources/Buttons/Buttons";

import SearchPlaceholder from "../Widgets/SearchPlaceholder/SearchPlaceholder";
import ReportSendTable from "./ReportSendTable/ReportSendTable";

const ReportSend = () => {

    return (
        <Paper
            elevation={3}
            className={"report_send"}
            sx={{borderRadius: 3}}
        >
            <Box className={"report_send-container"}>
                <Box className={"report_send-container_title"}>
                    <Box className={"report_send-container_title-item"}>
                        <MainTitle initial={"Таблица оперативных донесений"}/>
                    </Box>
                    <Box className={"report_send-container_title-divider"}>
                        <Divider/>
                    </Box>
                </Box>
                <Box className={"report_send-container_search"}>
                    <Box className={"report_send-container_search-filter"}>
                        <Box className={"report_send-container_search-filter_interval"}>
                            <IntervalDropdown initial={"Интервал"}/>
                        </Box>
                        <Box className={"report_send-container_search-filter_panel"}>
                            <SearchPlaceholder/>
                        </Box>
                    </Box>
                    <Box className={"report_send-container_search-button"}>
                        <Link to="../Pages/ReportModalPage">
                            <ButtonSend initial={"Добавить донесение"}/>
                        </Link>
                    </Box>
                </Box>
                <Box className={"report_send-container_table"}>
                    <ReportSendTable/>
                </Box>
                <Box className={"report_send-container_footer"}>
                    <Box className={"report_main-container_footer-count"}>
                        <ChildTitle initial={`Показано строк ${6} из ${350}`}/>
                    </Box>
                    <Box className={"report_send-container_footer-button"}>
                        <ButtonShowMore initial={"Показать ещё"}/>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}

export default ReportSend;