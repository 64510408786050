import './DashboardInfo.scss';

import { Box } from "@mui/material";

import DashboardInfoTable from "./DashboardInfoTable/DashboardInfoTable";

const DashboardInfo = () => {

    return (
        <Box className={"dashboard_info"}>
            <Box className={"dashboard_info_container"}>
                <Box className={"dashboard_info_container-table"}>
                    <DashboardInfoTable/>
                </Box>
            </Box>
        </Box>
    )
}

export default DashboardInfo;