import './AnalyticsSection.scss';

import { Box } from "@mui/material";

import StatisticsBranches from "../Widgets/StatisticsBranches/StatisticsBranches";
import StatisticsAccident from "../Widgets/StatisticsAccident/StatisticsAccident";
import StatisticsWorkers from "../Widgets/StatisticsWorkers/StatisticsWorkers";

const AnalyticsSection = () => {

    return (
        <Box className={"analytics_section"}>
            <Box className={"analytics_section_container"}>
                <Box className={"analytics_section_container_wrap"}>
                    <Box className={"analytics_section_container_wrap_accident"}>
                        <StatisticsBranches/>
                    </Box>
                    <Box className={"analytics_section_container_wrap_incident"}>
                        <StatisticsAccident/>
                    </Box>
                    <Box className={"analytics_section_container_wrap_accident"}>
                        <StatisticsWorkers/>
                    </Box>
                    <Box className={"analytics_section_container_wrap_incident"}>
                        <StatisticsAccident/>
                    </Box>
                    <Box className={"analytics_section_container_wrap_accident"}>
                        <StatisticsBranches/>
                    </Box>
                    <Box className={"analytics_section_container_wrap_incident"}>
                        <StatisticsAccident/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AnalyticsSection;