import * as XLSX from 'xlsx';

import { Box } from "@mui/material";
import { Button } from "@mui/material";

import { TableChart } from "@mui/icons-material";
import { TrendingUp } from "@mui/icons-material";
import { Book } from "@mui/icons-material";
import { Update } from "@mui/icons-material";
import { Print } from "@mui/icons-material";
import { ArrowBack } from "@mui/icons-material";
import { EditNote } from "@mui/icons-material";
import { Email } from "@mui/icons-material";
import { IosShare } from "@mui/icons-material";


export const ButtonPromptDeparture = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{height: "50px"}}
                variant={"contained"}>
                <Box
                    sx={{display: "flex",
                         alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                              marginRight: "5px"}}>
                        <TableChart/>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonDashboard = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{height: "50px"}}
                variant={"contained"}>
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                        marginRight: "5px"}}>
                        <TableChart/>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonSend = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{height: "50px"}}
                variant={"contained"}>
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                        marginRight: "5px"}}>
                        <EditNote/>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonJournal = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{height: "50px"}}
                variant={"contained"}>
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                        marginRight: "5px"}}>
                        <Book/>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonUpdate = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{height: "50px"}}
                variant={"contained"}>
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                        marginRight: "5px"}}>
                        <Update/>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonStatistic = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{height: "50px"}}
                variant={"contained"}
            >
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                        marginRight: "5px"}}>
                        <TrendingUp/>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonOperations = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{height: "50px"}}
                variant={"contained"}>
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                        marginRight: "5px"}}>
                        <TableChart/>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonPrint = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{height: "50px"}}
                variant={"contained"}>
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                        marginRight: "5px"}}>
                        <Print/>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonBack = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{width: "150px", height: "50px"}}
                variant="contained">
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                              marginRight: "10px"}}>
                        <ArrowBack/>
                    </Box>
                    <Box sx={{
                        textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonShowMore = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{width: "200px", height: "50px"}}
                variant={"contained"}>
                <Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonSendReport = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{height: "50px"}}
                variant={"contained"}>
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                        marginRight: "5px"}}>
                        <Email/>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonPresentation = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{height: "50px"}}
                variant={"contained"}>
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                        marginRight: "5px"}}>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonBackMainPage = ({initial}) => {
    return (
        <Box>
            <Button
                sx={{width: "200px", height: "50px"}}
                variant={"contained"}>
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                        marginRight: "10px"}}>
                        <ArrowBack/>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};

export const ButtonExport = ({initial, handleExport}) => {


    return (
        <Box>
            <Button
                sx={{height: "50px"}}
                variant={"contained"}
                onClick={handleExport}
            >
                <Box
                    sx={{display: "flex",
                        alignItems: "center"}}>
                    <Box sx={{marginTop: "6px",
                        marginRight: "5px"}}>
                        <IosShare/>
                    </Box>
                    <Box
                        sx={{textAlign: "center"}}>
                        {initial}
                    </Box>
                </Box>
            </Button>
        </Box>
    )
};


