
export const Logo = () => {

    return (
        <svg width="auto" height="80" viewBox="0 0 400 80" xmlns="http://www.w3.org/2000/svg">
            <text x="25%" y="50%" font-family="Arial, sans-serif" font-size="35" fill="black" text-anchor="middle"
                  alignment-baseline="middle">
                <tspan fill="#fff" dy="0">ResQ</tspan>
                <tspan fill="#8bc34a" dy="0">Core</tspan>
            </text>
        </svg>
    )
}

export const LogoMain = () => {

    return (
        <svg width="800" height="230" viewBox="0 0 800 400" xmlns="http://www.w3.org/2000/svg">
            <text x="50%" y="20%" font-family="Arial, sans-serif" font-size="150" fill="black" text-anchor="middle"
                  alignment-baseline="central" dominant-baseline="central">

                <tspan fill="#fff" dy="0">ResQ</tspan>
                <tspan fill="#8bc34a" dy="0">Core</tspan>
            </text>
        </svg>
    )
}
