import './Titles.scss';

import { Box } from "@mui/material";

export const MainTitle = ({initial}) => {

    return (
        <Box className={"main_title"}>
            <Box className={"main_title_content"}>
                {initial}
            </Box>
        </Box>
    )
}

export const SecondTitle = ({initial}) => {

    return (
        <Box className={"second_tittle"}>
            <Box className={"second_title_content"}>
                {initial}
            </Box>
        </Box>
    )
}

export const LogoTitle = ({initial}) => {

    return (
        <Box className={"logo_title"}>
            <Box className={"logo_title_content"}>
                {initial}
            </Box>
        </Box>
    )
}

export const NotificationTitle = ({initial}) => {

    return (
        <Box className={"notification_title"}>
            <Box className={"notification_title_content"}>
                {initial}
            </Box>
        </Box>
    )
}

export const ChildTitle = ({initial}) => {

    return (
        <Box className={"child_title"}>
            <Box className={"child_title_content"}>
                {initial}
            </Box>
        </Box>
    )
}