export const _transformBranches = {
    "2": "Актюбинский",
    "3": "Атырауский",
    "4": "Восточно-Казахстанский",
    "5": "Западно-Казахстанский",
    "6": "Карагандинский",
    "7": "Кызылординский",
    "8": "Мангистауский",
    "9": "Павлодарский",
    "10": "Северо-Казахстанский",
    "11": "Южно-Казахстанский",
    "12": "Акмолинский"
};