import './SearchPlaceholder.scss';

import { useDispatch } from "react-redux";

import { Box } from "@mui/material";
import { Paper } from "@mui/material";
import { Divider } from "@mui/material";
import { InputBase } from "@mui/material";

import { Search } from "@mui/icons-material";

const SearchPlaceholder = ({ setSearchText }) => {
    const dispatch = useDispatch();
    const handleSearchDepartures = (e) => {
        dispatch(setSearchText(e.target.value));
    }

    return (
        <Paper component="form" className={"search"}>
            <Box className={"search_container"}>
                <Box className={"search_container-icon"}>
                    <Search sx={{color: "#1976d2"}}/>
                </Box>
                <Box className={"search_container-divider"}>
                    <Divider orientation="vertical"/>
                </Box>
                <Box className={"search_container-inputbase"}>
                    <InputBase
                        placeholder={"Поиск по объекту, номеру донесения, типу аварии и т.д"}
                        inputProps={{"aria-label" : "Поиск"}}
                        className={"search_container-inputbase_item"}
                        onChange={handleSearchDepartures}
                    />
                </Box>
            </Box>
        </Paper>
    )
}

export default SearchPlaceholder;