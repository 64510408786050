import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "../api/api";
import { _transformOperationMain } from "../services/_transformOperationMain";

const initialState = {
    departures: [],
    interval: '',
    week: '',
    month: '',
    year: '',
    dateFrom: '',
    dateTo: '',
    selectedRangeFilter: false,
    selectedMonthFilter: false,
    selectedYearFilter: false
};

export const fetchTableOperations = createAsyncThunk(
    'tableOperations/fetchTableOperations',
    async(filter) => {

        const data =  await request(
            'https://b24.centrspas.kz/api/departures.items',
            {
                method: 'POST',
                body: {filter: filter}
            })


        return data.departures.map(_transformOperationMain);
    }
);

const dateFilterSlice = createSlice({
    name: 'dateFilter',
    initialState,
    reducers: {
        setIntervalFilter: (state, action) => {
            state.interval = action.payload;
        },
        setWeekFilter: (state, action) => {
            state.week = action.payload
        },
        setMonthFilter: (state, action) => {
            state.interval = action.payload;
        },
        setYearFilter: (state, action) => {
            state.year = action.payload;
        },
        setDateFrom: (state, action) => {
            state.dateFrom = action.payload;
        },
        setDateTo: (state, action) => {
            state.dateTo = action.payload;
        },
        setSelectedMonthFilter: (state, action) => {
            state.selectedMonthFilter = action.payload;
        },
        setSelectedYearFilter: (state, action) => {
            state.selectedYearFilter = action.payload;
        },
        setSelectedRangeFilter: (state, action) => {
            state.selectedRangeFilter = action.payload;
        }
    }
});

export const {
    setIntervalFilter,
    setMonthFilter,
    setYearFilter,
    setWeekFilter,
    setDateFrom,
    setDateTo,
    setSelectedMonthFilter,
    setSelectedYearFilter,
    setSelectedRangeFilter
} = dateFilterSlice.actions;

export default dateFilterSlice.reducer;