import './OperationsQuantity.scss';

import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import * as XLSX from 'xlsx';

import { setSelectedLineEmergency } from "../../slices/tableQuantitySlice";
import { setSelectedIncident } from "../../slices/tableQuantitySlice";
import { setSelectedInterval } from "../../slices/tableQuantitySlice";
import { setSelectedWeek } from "../../slices/tableQuantitySlice";
import { setSelectedMonth } from "../../slices/tableQuantitySlice";
import { setSelectedYear } from "../../slices/tableQuantitySlice";
import { setStartDate } from "../../slices/tableQuantitySlice";
import { setEndDate } from "../../slices/tableQuantitySlice";

import { fetchQuantityTable } from "../../slices/tableQuantitySlice";

import { _transformIncidents } from "../../services/_transformIncidents";
import { _transformBranches } from "../../services/_transformBranches";

import { Box } from "@mui/material";
import { Divider } from "@mui/material";

import { AccidentDropdown } from "../../resources/Dropdowns/Dropdowns";
import { LineDropdownFilter } from "../../resources/Dropdowns/Dropdowns";
import { IntervalDropdown } from "../../resources/Dropdowns/Dropdowns";
import { DateFilter } from "../../resources/Dropdowns/Dropdowns";
import { MainTitle } from "../../resources/Titles/Titles";
import { ButtonExport } from "../../resources/Buttons/Buttons";
import { ErrorMessage } from "../../resources/Messages/Messages";

import { TableSkeleton } from "../../resources/Skeleton/Skeleton";

import OperationQuantityTable from "./OperationsQuantityTable/OperationsQuantityTable";
import Filters from "../Widgets/Filters/Filters";

const OperationsQuantity = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.tableQuantity.data);
    const selectedLineEmergency = useSelector((state) => state.tableQuantity.selectedLineEmergency);
    const selectedIncident = useSelector((state => state.tableQuantity.selectedIncident));
    const selectedInterval = useSelector((state => state.tableQuantity.selectedInterval));
    const selectedWeek = useSelector(state => state.tableQuantity.selectedWeek);
    const selectedMonth = useSelector(state => state.tableQuantity.selectedMonth);
    const selectedYear = useSelector(state => state.tableQuantity.selectedYear);
    const startDate = useSelector(state => state.tableQuantity.startDate);
    const endDate = useSelector(state => state.tableQuantity.endDate);
    const status = useSelector((state) => state.tableQuantity.tableQuantityLoadingStatus);
    let content = null;

    const createFilters = () => {
        let filters = {};

        if (selectedInterval === 'week') {
            filters.date = 'week';
        } else if (selectedInterval === 'month') {
            filters.date = 'month';
            filters.month = selectedMonth;
        } else if (selectedInterval === 'year') {
            filters.date = 'year';
            filters.year = selectedYear;
        } else if (selectedInterval === 'range') {
            filters.date_from = startDate;
            filters.date_to = endDate;
        }

        return filters;
    }

    const filteredQuantity = data.filter(incident => {
        const currentIncident = selectedIncident ? _transformIncidents[selectedIncident] : null;

        if (currentIncident) {
            return incident.name === currentIncident
        } else {
            return true
        }
    })

    useEffect(() => {
        const filters = createFilters({
            selectedInterval,
            selectedYear,
            selectedMonth,
            startDate,
            endDate
        });


        dispatch(fetchQuantityTable(filters));
    }, [dispatch, selectedInterval, selectedYear, selectedMonth, startDate, endDate]);


    if (status === 'loading') {
        content = <TableSkeleton/>
    } else if (status === 'error') {
        content = <ErrorMessage/>
    } else {
        content = <OperationQuantityTable data={filteredQuantity}/>
    }

    const handleExport = () => {
        const dataSet = filteredQuantity.map(row => {
            const newRow = {
                'Наименование аварийной ситуации': row.name,
            };

            Object.keys(_transformBranches).forEach(branchId => {
                newRow[_transformBranches[branchId]] = row[`branch_${branchId}`];
            });

            newRow['Всего'] = row.total;
            return newRow;
        });

        const worksheet = XLSX.utils.json_to_sheet(dataSet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Operations Quantity");

        XLSX.writeFile(workbook, "Operations_Quantity.xlsx");
    };


    return (
        <Box className={"operations_quantity"}>
            <Box className={"operations_quantity_container"}>
                <Box className={"operations_quantity_container_title"}>
                    <Box className={"operations_quantity_container_title_item"}>
                        <MainTitle initial={"Количество выездов по видам"}/>
                    </Box>
                    <Box className={"operations_quantity_container_title_divider"}>
                        <Divider/>
                    </Box>
                </Box>
                <Box className={"operations_quantity_container_search"}>
                    <Box className={"operations_quantity_container_search_filter"}>
                        <Filters
                            incidentFilter={
                                <AccidentDropdown
                                    initial={"Авария"}
                                    selectedIncident={selectedIncident}
                                    setSelectedIncident={setSelectedIncident}
                                />
                            }
                            lineFilter={
                                <LineDropdownFilter
                                    initial={"ЧС"}
                                    selectedLineEmergency={selectedLineEmergency}
                                    setSelectedLineEmergency={setSelectedLineEmergency}
                                />
                            }
                            intervalFilter={({setShowYear, setShowMonth, setShowRange}) => (
                                <IntervalDropdown
                                    initial={"Интервал"}
                                    interval={selectedInterval}
                                    setFilterInterval={setSelectedInterval}
                                    setShowYear={setShowYear}
                                    setShowMonth={setShowMonth}
                                    setShowRange={setShowRange}
                                />
                            )}
                            selectedYear={selectedYear}
                            setSelectedYear={setSelectedYear}
                            selectedMonth={selectedMonth}
                            setSelectedMonth={setSelectedMonth}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />
                    </Box>
                    <Box className={"operations_quantity_container_search_button"}>
                        <ButtonExport initial={"Экспорт в Excel"} handleExport={handleExport}/>
                    </Box>
                </Box>
                <Box className={"operations_quantity_container_table"}>
                    {content}
                </Box>
            </Box>
        </Box>
    )
}

export default OperationsQuantity;