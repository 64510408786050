import './ReportJournal.scss';

import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { fetchTableJournal } from "../../slices/tableJournalSlice";

import { Box } from "@mui/material";
import { Paper } from "@mui/material";
import { Divider } from "@mui/material";

import { MainTitle } from "../../resources/Titles/Titles";
import { TableSkeleton } from "../../resources/Skeleton/Skeleton";
import { ErrorMessage } from "../../resources/Messages/Messages";

import ReportJournalTable from "./ReportJournalTable/ReportJournalTable";

const ReportJournal = ({reportId}) => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.tableJournal.data);
    const status = useSelector(state => state.tableJournal.journalLoadingStatus);
    let content = null;

    useEffect(() => {
        if (reportId) {
            dispatch(fetchTableJournal(reportId))
        }
    }, [dispatch, reportId]);

    if (status === 'loading') {
        content = <TableSkeleton/>
    } else if (status === 'error') {
        content = <ErrorMessage/>
    } else {
        content = <ReportJournalTable data={data}/>
    }


    return (
        <Box
            className={"report_journal"}
            sx={{borderRadius: 3}}
        >
            <Box className={"report_journal_container"}>
                <Box className={"report_journal_container-title"}>
                    <MainTitle initial={"Журнал"}/>
                    <Box className={"report_journal_container-title_divider"}>
                        <Divider/>
                    </Box>
                </Box>
                <Box className={"report_journal_container-table"}>
                    {content}
                </Box>
            </Box>
        </Box>
    )
}

export default ReportJournal;