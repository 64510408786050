export const _transformDepartureDashboard = (info) => {

    return {
        reporting_id: info.reporting_id,
        branch_id: info.branch_id,
        location_accident: info.location_accident,
        call_date: info.call_date,
        emergency_name: info.emergency_name,
        status: info.status
    }
}