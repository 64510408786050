import './OperationsQuantityTable.scss';

import { useState } from "react";

import {_transformIncidents} from "../../../services/_transformIncidents";
import {_transformBranches} from "../../../services/_transformBranches";

import { Paper } from "@mui/material";
import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";


const OperationQuantityTable = ({data}) => {
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const handleRowClick = (index) => {
        setSelectedRowIndex(index);
    }

    return (
        <TableContainer
            elevation={3}
            className={"quantity_table"}
            component={Paper}
        >
            <Table className={"quantity_table_container"}>
                <TableHead className={"quantity_table_container_header"}>
                    <TableRow className={"quantity_table_container_header_row"} sx={{backgroundColor: "#1976d2"}}>
                        <TableCell sx={{ color: "#fff" }} align="left">
                            Наименование аварийной ситуации
                        </TableCell>
                        {Object.values(_transformBranches).map((branchName, index) => (
                            <TableCell
                                key={index}
                                sx={{ color: "#fff" }}
                                align="center"
                                className={"vertical-text"}
                            >
                                {branchName}
                            </TableCell>
                        ))}
                        <TableCell sx={{ color: "#fff" }} align="center">
                            Всего
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={"quantity_table_container_body"}>
                    {data.map((row, index) => (
                        <TableRow
                            key={index}
                            onClick={() => handleRowClick(index)}
                            sx={{
                                backgroundColor: selectedRowIndex === index ? "#4dabf5" : "inherit",
                                cursor: "pointer",
                            }}
                        >
                            <TableCell sx={{ color: selectedRowIndex === index ? "#fff" : "inherit" }} align="left">
                                {row.name}
                            </TableCell>
                            {Object.keys(_transformBranches).map(branchId => (
                                <TableCell
                                    key={branchId}
                                    sx={{ color: selectedRowIndex === index ? "#fff" : "inherit" }}
                                    align="center"
                                >
                                    {row[`branch_${branchId}`]}
                                </TableCell>
                            ))}
                            <TableCell sx={{ color: selectedRowIndex === index ? "#fff" : "inherit" }} align="center">
                                {row.total}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OperationQuantityTable;