import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createEntityAdapter } from "@reduxjs/toolkit";

import { request } from "../api/api";

import { _transformOperationMain } from "../services/_transformOperationMain";

const tableOperationsAdapter = createEntityAdapter();

const initialState = tableOperationsAdapter.getInitialState({
    departures: [],
    selectedBranch: '',
    selectedInterval: '',
    selectedWeek: '',
    selectedYear: '',
    selectedMonth: '',
    startDate: '',
    endDate: '',
    selectedLineEmergency: '',
    tableOperationsLoadingStatus: "idle"
});

export const fetchTableOperations = createAsyncThunk(
    'tableOperations/fetchTableOperations',
    async(filters) => {

        const body = {
            offset: 0,
            filter: filters
        }

        const data =  await request('https://b24.centrspas.kz/api/departures.items', 'POST', body);
        return data.departures.map(_transformOperationMain);
    }
);

const tableOperationsSlice = createSlice({
    name: 'tableOperations',
    initialState,
    reducers: {
        setSelectedLine: (state, action) => {
            state.selectedLineEmergency = action.payload
        },
        setSelectedBranch: (state, action) => {
            state.selectedBranch = action.payload
        },
        setSelectedInterval: (state, action) => {
            state.selectedInterval = action.payload
        },
        setSelectedYear: (state, action) => {
            state.selectedYear = action.payload
        },
        setSelectedMonth: (state, action) => {
            state.selectedMonth = action.payload
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTableOperations.pending, (state) => {
                state.tableOperationsLoadingStatus = 'loading';
            })
            .addCase(fetchTableOperations.fulfilled, (state, action) => {
                state.departures = action.payload;
                state.tableOperationsLoadingStatus = 'idle';
            })
            .addCase(fetchTableOperations.rejected, (state) => {
                state.tableOperationsLoadingStatus = 'error';
            })
    }
})

export const {
    setSelectedBranch,
    setSelectedLine,
    setSelectedInterval,
    setSelectedYear,
    setSelectedMonth,
    setStartDate,
    setEndDate
} = tableOperationsSlice.actions;
export default tableOperationsSlice.reducer;