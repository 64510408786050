import './ReportDetailSecond.scss';

import { Box } from "@mui/material";

import { SecondTitle } from "../../../resources/Titles/Titles";
import { ChildTitle } from "../../../resources/Titles/Titles";

const ReportDetailSecond = (
    {
        minor_injury,
        severe_injury,
        fatal_injury,
        people_evacuated,
        provided_medical,
        scope_work,
        situation_filing
    }
) => {

    return (
        <Box className={"report_detail_second"}>
            <Box className={"report_detail_second_container"}>
                <Box className={"report_detail_second_container_row"}>
                    <Box className={"report_detail_second_container_row_title"}>
                        <SecondTitle initial={"11. Колличество пострадавших: "}/>
                    </Box>
                    <Box className={"report_detail_second_container_row_description"}>
                        <ChildTitle initial={
                            `
                            Легкая травма: ${minor_injury}, 
                            Тяжелая травма: ${severe_injury}, 
                            Смертельная травма: ${fatal_injury}`
                        }/>
                    </Box>
                </Box>
                <Box className={"report_detail_second_container_row"}>
                    <Box className={"report_detail_second_container_row_title"}>
                        <SecondTitle initial={"12. Эвакуировано людей: "}/>
                    </Box>
                    <Box className={"report_detail_second_container_row_description"}>
                        <ChildTitle initial={people_evacuated}/>
                    </Box>
                </Box>
                <Box className={"report_detail_second_container_row"}>
                    <Box className={"report_detail_second_container_row_title"}>
                        <SecondTitle initial={"14. Оказана медецинская помощь: "}/>
                    </Box>
                    <Box className={"report_detail_second_container_row_description"}>
                        <ChildTitle initial={provided_medical}/>
                    </Box>
                </Box>
                <Box className={"report_detail_second_container_row"}>
                    <Box className={"report_detail_second_container_row_title"}>
                        <SecondTitle initial={"15. Перечень и объем проведенных работ: "}/>
                    </Box>
                    <Box className={"report_detail_second_container_row_description"}>
                        <ChildTitle initial={scope_work}/>
                    </Box>
                </Box>
                <Box className={"report_detail_second_container_row"}>
                    <Box className={"report_detail_second_container_row_title"}>
                        <SecondTitle initial={"16. Ситуация на момент подачи донесения: "}/>
                    </Box>
                    <Box className={"report_detail_second_container_row_description"}>
                        <ChildTitle initial={situation_filing}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportDetailSecond;