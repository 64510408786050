import './ReportSendPage.scss';

import { Box } from "@mui/material";

import Header from "../../components/Widgets/Header/Header";
import ReportSend from "../../components/ReportSend/ReportSend";
import Navigation from "../../components/Widgets/Navigation/Navigation";

import { AktobeBranch } from "../../resources/Branch/Branch";
import { ButtonBack } from "../../resources/Buttons/Buttons";
import { ButtonSend } from "../../resources/Buttons/Buttons";
import {Link} from "react-router-dom";

const ReportSendPage = () => {

    return (
        <Box className={"report_send_page"}>
            <Header/>
            <Box className={"report_send_page-container"}>
                <Box className={"report_send_page-container_widget"}>
                    <Navigation
                        branch={
                            <AktobeBranch/>
                        }
                        backLink={
                            <Link to="/">
                                <ButtonBack
                                    initial={"Назад"}
                                />
                            </Link>
                        }
                    />
                </Box>
                <Box className={"report_send_page-container_table"}>
                    <ReportSend/>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportSendPage;