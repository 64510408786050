export const _transformOperationMain = (info) => {

    return {
        branch_id: info.branch_id,
        location_accident: info.location_accident,
        reporting_id: info.reporting_id,
        who_call: info.who_call,
        arrival_date: info.arrival_date,
        finish_work_date: info.finish_work_date,
        call_date: info.call_date,
        emergency_name: info.emergency_name,
        object_owner: info.object_owner,
        use_transport: info.use_transport,
        branch_workers: info.branch_workers,
        line_emergency: info.line_emergency
    }
}