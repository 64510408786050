import './ReportMainPage.scss';

import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import Header from "../../components/Widgets/Header/Header";
import ReportMain from "../../components/ReportMain/ReportMain";
import Navigation from "../../components/Widgets/Navigation/Navigation";

import { MainBranch } from "../../resources/Branch/Branch";
import {ButtonDashboard, ButtonOperations} from "../../resources/Buttons/Buttons";
import { ButtonBackMainPage } from "../../resources/Buttons/Buttons";


const ReportMainPage = () => {

    return (
        <Box className={"report_main_page"}>
            <Header/>
            <Box className={"report_main_page-container"}>
                <Box className={"report_main_page-container_widget"}>
                    <Navigation
                        branch={
                            <MainBranch/>
                        }
                        backLink={
                            <Link to="/">
                                <ButtonBackMainPage
                                    initial={"На главную"}
                                />
                            </Link>
                        }
                        dashboardLink={
                            <Link to="../Pages/DashboardPage">
                                <ButtonDashboard initial={"Дашборд"}/>
                            </Link>
                        }
                        operationsMainLink={
                            <Link to="../Pages/OperationsMainPage">
                                <ButtonOperations initial={"Таблица оперативных выездов"}/>
                            </Link>
                        }
                    />
                </Box>
                <Box className={"report_main_page-container_component"}>
                    <ReportMain/>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportMainPage;