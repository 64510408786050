import './DashboardMap.scss';

import { Box } from "@mui/material";

import { Map } from '../../resources/Map/Map'
const DashBoardMap = () => {

    return (
        <Box className={"accident_map"}>
            <Map/>
        </Box>
    )
}

export default DashBoardMap;