import './OperationsMainPage.scss';

import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import Navigation from "../../components/Widgets/Navigation/Navigation";
import OperationsMain from "../../components/OperationsMain/OperationsMain";
import Header from "../../components/Widgets/Header/Header";

import { MainBranch } from "../../resources/Branch/Branch";
import {ButtonBack, ButtonPromptDeparture} from "../../resources/Buttons/Buttons";

const OperationsMainPage = () => {

    return (
        <Box className={"operations_main_page"}>
            <Header/>
            <Box className={"operations_main_page_container"}>
                <Box className={"operations_main_page_container-widget"}>
                    <Navigation
                        branch={
                            <MainBranch/>
                        }
                        backLink={
                            <Link to="../Pages/ReportMainPage">
                                <ButtonBack
                                    initial={"Назад"}
                                />
                            </Link>
                        }
                        operationsQuantityLink={
                            <Link to="../Pages/OperationsQuantityPage">
                                <ButtonPromptDeparture initial={"Количество выездов по видам"}/>
                            </Link>
                        }
                    />
                </Box>
                <Box className={"operations_main_page_container-component"}>
                    <OperationsMain/>
                </Box>
            </Box>
        </Box>
    )
}

export default OperationsMainPage;