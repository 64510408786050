 import './Header.scss';

import { Box } from "@mui/material";
import { AppBar } from "@mui/material";
import { Toolbar } from "@mui/material";

import { Logo } from "../../../resources/Logo/Logo";

const Header = () => {

    return (
        <Box className={"header"}>
            <AppBar position="static" className={"header_body"}>
                <Toolbar className={"header_body-content"}
                         variant="h6">
                    <Box className={"header_body-content_logo"}>
                        <Logo/>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
export default Header;