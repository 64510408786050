import './ReportModalPage.scss';

import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import { ButtonBack } from "../../resources/Buttons/Buttons";
import { ButtonSendReport } from "../../resources/Buttons/Buttons";
import { AktobeBranch } from "../../resources/Branch/Branch";

import ReportModalMain from "../../components/ReportModals/ReportModalMain/ReportModalMain";
import ReportModalSecond from "../../components/ReportModals/ReportModalSecond/ReportModalSecond";
import ReportThirdModal from "../../components/ReportModals/ReportThirdModal/ReportThirdModal";
import Navigation from "../../components/Widgets/Navigation/Navigation";
import Header from "../../components/Widgets/Header/Header";

const ReportModalPage = () => {

    return (
        <Box className={"report_modal_page"}>
            <Header/>
            <Box className={"report_modal_page_container"}>
                <Navigation
                    branch={
                        <AktobeBranch/>
                    }
                    backLink={
                        <Link to="../Pages/ReportSendPage">
                            <ButtonBack
                                initial={"Назад"}
                            />
                        </Link>
                    }
                />
                <Box className={"report_modal_page_container_item"}>
                    <ReportModalMain/>
                </Box>
                <Box className={"report_modal_page_container_item"}>
                    <ReportModalSecond/>
                </Box>
                <Box className={"report_modal_page_container_item"}>
                    <ReportThirdModal/>
                </Box>
                <Box className={"report_modal_page_container_button"}>
                    <ButtonSendReport initial={"Отправить донесение"}/>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportModalPage;