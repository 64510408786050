import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "../api/api";

const initialState = {
    accidents: {},
    counterValueLoadingStatus: 'idle',
};

export const fetchCounterValue = createAsyncThunk(
    'counterValue/fetchCounterValue',
    async () => {
        const data = await request('https://b24.centrspas.kz/api/accident.count');
        return data.accidents;
    }
);

const counterValueSlice = createSlice({
    name: 'counterValue',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCounterValue.pending, (state) => {
                state.counterValueLoadingStatus = 'loading';
            })
            .addCase(fetchCounterValue.fulfilled, (state, action) => {
                state.accidents = action.payload;
                state.counterValueLoadingStatus = 'idle';
            })
            .addCase(fetchCounterValue.rejected, (state) => {
                state.counterValueLoadingStatus = 'error';
            });
    },
});

export default counterValueSlice.reducer;