import './Navigation.scss';

import { Box } from "@mui/material";

const Navigation = ({
    branch,
    dashboardLink,
    promptDepartureLink,
    backLink,
    statisticLink,
    journalLink,
    updateLink,
    printLink,
    presentationLink,
    operationsMainLink,
    operationsQuantityLink
}) => {

    return (
        <Box className={"navigation_widget"}>
            <Box className={"navigation_widget-container"}>
                <Box className={"navigation_widget-container_buttons"}>
                    <Box className={"navigation_widget-container_buttons-items"}>
                        {promptDepartureLink && (
                            <Box className={"navigation_widget-container_buttons-items_link"}>
                                {promptDepartureLink}
                            </Box>
                        )}
                        {backLink && (
                            <Box className={"navigation_widget-container_buttons-items_link"}>
                                {backLink}
                            </Box>
                        )}

                        {journalLink && (
                            <Box className={"navigation_widget-container_buttons-items_link"}>
                                {journalLink}
                            </Box>
                        )}
                        {printLink && (
                            <Box className={"navigation_widget-container_buttons-items_link"}>
                                {printLink}
                            </Box>
                        )}
                        {updateLink && (
                            <Box className={"navigation_widget-container_buttons-items_link"}>
                                {updateLink}
                            </Box>
                        )}
                        {presentationLink && (
                            <Box className={"navigation_widget-container_buttons-items_link"}>
                                {presentationLink}
                            </Box>
                        )}
                        {operationsMainLink && (
                            <Box className={"navigation_widget-container_buttons-items_link"}>
                                {operationsMainLink}
                            </Box>
                        )}
                        {operationsQuantityLink && (
                            <Box className={"navigation_widget-container_buttons-items_link"}>
                                {operationsQuantityLink}
                            </Box>
                        )}
                        {statisticLink && (
                            <Box className={"navigation_widget-container_buttons-items_link"}>
                                {statisticLink}
                            </Box>
                        )}
                        {dashboardLink && (
                            <Box className={"navigation_widget-container_buttons-items_link"}>
                                {dashboardLink}
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box className={"navigation_widget-container_info"}>
                    <Box className={"navigation_widget-container_info-branch"}>
                        <Box className={"navigation_widget-container_info-branch_item"}>
                            {branch}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Navigation;