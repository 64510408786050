import ReactECharts from "echarts-for-react";

import { Paper } from "@mui/material";
import { Box } from "@mui/material";

const options = {
    tooltip: {
        trigger: 'item'
    },
    legend: {
        top: '5%',
        left: 'center'
    },
    series: [
        {
            name: 'Access Form',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: 40,
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                { value: 1048, name: 'Отключение электричества' },
                { value: 735, name: 'Пожар' },
                { value: 580, name: 'Загазованность' }
            ]
        }
    ]
}

const StatisticsAccident = () => {

    return (
        <Paper className={"statistics_incident"}>
            <Box className={"statistics_incident_container"}>
                <ReactECharts option={options}/>
            </Box>
        </Paper>
    )
}

export default StatisticsAccident;