import './Messages.scss';

import { Box } from "@mui/material";
import { Error } from "@mui/icons-material";
import { QuestionMark } from "@mui/icons-material";

import { NotificationTitle } from "../Titles/Titles";

export const ErrorMessage = () => {

    return (
        <Box className={"counter_error"}>
            <Box className={"counter_error_container"}>
                <Box className={"counter_error_container-icon"}>
                    <Error sx={{fontSize: "100px", color: "#e53935"}}/>
                </Box>
                <Box className={"counter_error_container-title"}>
                    <NotificationTitle initial={"Ошибка, нет данных"}/>
                </Box>
            </Box>
        </Box>
    )
}

export const EmptyMessage = () => {

    return (
        <Box className={"counter_error"}>
            <Box className={"counter_error_container"}>
                <Box className={"counter_error_container-icon"}>
                    <QuestionMark sx={{fontSize: "100px", color: "#bdbdbd"}}/>
                </Box>
                <Box className={"counter_error_container-title"}>
                    <NotificationTitle initial={"Ничего не найдено"}/>
                </Box>
            </Box>
        </Box>
    )
}

