import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "../api/api";

import { _transformJournal } from "../services/_transformJournal";

const initialState = {
    data: [],
    journalLoadingStatus: 'idle'
}

export const fetchTableJournal = createAsyncThunk(
    'tableJournal/fetchTableJournal',
    async(reportId) => {

        const body = {
            id: reportId
        }

        const response = await request(
            'https://b24.centrspas.kz/api/departures.journal',
            'POST',
            body
        );

        return response.data.map(_transformJournal);
    }
)

const tableJournalSlice = createSlice({
    name: 'tableJournal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTableJournal.pending, (state) => {
                state.journalLoadingStatus = 'loading';
            })
            .addCase(fetchTableJournal.fulfilled, (state, action) => {
                state.data = action.payload;
                state.journalLoadingStatus = 'idle'
            })
            .addCase(fetchTableJournal.rejected, (state) => {
                state.journalLoadingStatus = 'error'
            })
    }
})

export default tableJournalSlice.reducer;