import './Map.scss';

import { useEffect, useRef } from "react";
import { Box } from "@mui/material";

import tt from '@tomtom-international/web-sdk-maps'
import '@tomtom-international/web-sdk-maps/dist/maps.css'

export const Map = () => {
    const mapElement = useRef(null);
    const marker = useRef(null);

    const coordinates = [
        [51.8925, 47.1122],
        [57.1667, 50.2833],
        [51.1985, 43.6316],
        [65.5099, 44.8224],
        [51.3706, 51.2205],
        [71.3807, 42.8985],
        [82.6195, 49.9471],
        [73.0944, 49.8106],
        [63.1347, 52.9615],
        [75.3223, 51.7266],
        [71.8875, 52.3550],
    ];

    useEffect(() => {
        const map = tt.map({
            key: "T7e7pINpTlqFErjHtLOKBbkZGD9gPlGL",
            container: mapElement.current,
            center: [70.400, 47.9237],
            zoom: 4.0
        });

        coordinates.forEach(coord => {

            new tt.Marker()
                .setLngLat(coord)
                .addTo(map);
        });


        return () => map.remove();
    }, []);

    return (
        <Box ref={mapElement} className={"map_container"}>

        </Box>
    )
}
