import './MainPage.scss';

import { Box } from "@mui/material";

import Main from "../../components/Main/Main";

const MainPage = () => {

    return (
        <Box className={"main_page"}>
            <Box className={"main_page_container"}>
                <Box className={"main_page_container_component"}>
                    <Main/>
                </Box>
            </Box>
        </Box>
    )
}

export default MainPage;