import { configureStore } from "@reduxjs/toolkit";

import counterValue from '../slices/counterValueSlice';
import tableMain from '../slices/tableMainSlice';
import tableJournal from '../slices/tableJournalSlice';
import dateFilter from '../slices/dateFilterSlice';
import tableQuantity from '../slices/tableQuantitySlice';
import tableDashboard from '../slices/tableDashboardSlice';
import tableOperations from '../slices/tableOperationSlice';
import reportDetail from '../slices/detailSlice';

const stringMiddleware = () => (next) => (action) => {
    if (typeof action === 'string') {
        return next({
            type: action
        })
    }

    return next(action);
};

const store = configureStore({
    reducer: {
        counterValue,
        tableMain,
        tableJournal,
        tableQuantity,
        dateFilter,
        tableDashboard,
        tableOperations,
        reportDetail
    },
    middleware: getDefaultMiddleware =>
                getDefaultMiddleware()
                    .concat(stringMiddleware),
    devTools: {
        logOnly: false
    },
});

export default store;