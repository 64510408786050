import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { request } from "../api/api";

import { _transformQuantity } from "../services/_transformQuantity";

const initialState = {
    data: [],
    rawData: [],
    tableQuantityLoadingStatus: 'idle',
    selectedLineEmergency: '',
    selectedInterval: '',
    selectedWeek: '',
    selectedMonth: '',
    selectedYear: '',
    startDate: '',
    endDate: '',
    selectedIncident: ''
};

export const fetchQuantityTable = createAsyncThunk(
    'tableQuantity/fetchQuantityTable',
    async(filters) => {

        const body = {
            offset: 0,
            filter: filters
        }

        const data = await request('https://b24.centrspas.kz/api/departures.table', 'POST', body);
        return data.data;
    }
);

const tableQuantitySlice = createSlice({
    name: 'tableQuantity',
    initialState,
    reducers: {
        setSelectedIncident: (state, action) => {
            state.selectedIncident = action.payload
        },
        setSelectedLineEmergency: (state, action) => {
            state.selectedLineEmergency = action.payload;
            state.data = _transformQuantity(state.rawData, action.payload);
        },
        setSelectedInterval: (state, action) => {
            state.selectedInterval = action.payload;
        },
        setSelectedWeek: (state, action) => {
            state.selectedWeek = action.payload;
        },
        setSelectedMonth: (state, action) => {
            state.selectedMonth = action.payload;
        },
        setSelectedYear: (state, action) => {
            state.selectedYear = action.payload;
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchQuantityTable.pending, (state) => {
                state.tableQuantityLoadingStatus = 'loading';
            })
            .addCase(fetchQuantityTable.fulfilled, (state, action) => {
                state.rawData = action.payload;
                state.data = _transformQuantity(action.payload, state.selectedLineEmergency);
                state.tableQuantityLoadingStatus = 'idle';
            })
            .addCase(fetchQuantityTable.rejected, (state) => {
                state.tableQuantityLoadingStatus = 'error';
            })
    }
});

export const {
    setSelectedLineEmergency,
    setSelectedIncident,
    setSelectedInterval,
    setSelectedWeek,
    setSelectedMonth,
    setSelectedYear,
    setStartDate,
    setEndDate
} = tableQuantitySlice.actions;
export default tableQuantitySlice.reducer;