import './css/style.scss';

import { useState } from "react";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Header from "../components/Widgets/Header/Header";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import ReportModalPage from "../pages/ReportModalPage/ReportModalPage";
import MainPage from "../pages/MainPage/MainPage";
import Login from "../components/Login/Login";
import ReportMainPage from "../pages/ReportMainPage/ReportMainPage";
import ReportSendPage from "../pages/ReportSendPage/ReportSendPage";
import ReportDetailPage from "../pages/ReportDetailPage/ReportDetailPage";
import ReportJournalPage from "../pages/ReportJournalPage/ReportJournalPage";
import OperationsMainPage from "../pages/OperationsMainPage/OperationsMainPage";
import OperationsQuantityPage from "../pages/OperationsQuantityPage/OperationsQuantityPage";
import AnalyticsSection from "../components/AnalyticsSection/AnalyticsSection";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);


    return (
        <Router>
            <div className={"app"}>
                <Routes>
                    <Route path="/" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                    <Route path="/Main" element={<PrivateRoute element={<MainPage />} isAuthenticated={isAuthenticated} />} />
                    <Route path="/Pages/DashboardPage" element={<PrivateRoute element={<DashboardPage />} isAuthenticated={isAuthenticated} />} />
                    <Route path="/Pages/ReportMainPage" element={<PrivateRoute element={<ReportMainPage />} isAuthenticated={isAuthenticated} />} />
                    <Route path="/components/AnalyticsSection" element={<PrivateRoute element={<AnalyticsSection />} isAuthenticated={isAuthenticated} />} />
                    <Route path="/Pages/ReportSendPage" element={<PrivateRoute element={<ReportSendPage />} isAuthenticated={isAuthenticated} />} />
                    <Route path="/Pages/ReportDetailPage/:reportId" element={<PrivateRoute element={<ReportDetailPage />} isAuthenticated={isAuthenticated} />} />
                    <Route path="/Pages/ReportJournalPage/:reportId" element={<PrivateRoute element={<ReportJournalPage />} isAuthenticated={isAuthenticated} />} />
                    <Route path="/Pages/ReportModalPage" element={<PrivateRoute element={<ReportModalPage />} isAuthenticated={isAuthenticated} />} />
                    <Route path="/Pages/OperationsMainPage" element={<PrivateRoute element={<OperationsMainPage />} isAuthenticated={isAuthenticated} />} />
                    <Route path="/Pages/OperationsQuantityPage" element={<PrivateRoute element={<OperationsQuantityPage />} isAuthenticated={isAuthenticated} />} />
                </Routes>
            </div>
        </Router>
    )
}

export default App;
