import './Main.scss';

import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import { LogoMain } from "../../resources/Logo/Logo";

import { ButtonPromptDeparture } from "../../resources/Buttons/Buttons";
import { ButtonOperations } from "../../resources/Buttons/Buttons";


const Main = () => {

    return (
        <Box className={"main"}>
            <Box className={"main_container"}>
                <Box className={"main_container_logo"}>
                    <LogoMain/>
                </Box>
                <Box className={"main_container_navigation"}>
                    <Box className={"main_container_navigation_link"}>
                        <Link to={"../Pages/ReportMainPage"}>
                            <ButtonPromptDeparture initial={"Оперативные донесения"}/>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Main;
