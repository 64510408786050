import './ReportMainTable.scss'

import { Link } from "react-router-dom";

import { Paper } from "@mui/material";
import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";

import { Danger } from "../../../resources/Status/Status";
import { Success } from "../../../resources/Status/Status";

const ReportMainTable = ({departures}) => {

    return (
        <TableContainer elevation={3} component={Paper} className={"table_main"}>
            <Table className={"table_main_container"}>
                <TableHead className={"table_main_container-header"} sx={{backgroundColor: "#1976d2"}}>
                    <TableRow className={"table_main_container-header_row"}>
                        <TableCell sx={{color: "#fff"}} align="left">Номер</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Кто отправил донесение</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Объект</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Дата</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Тип аварии</TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">Статус</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={"table_main_container-body"}>
                    {departures.map((rows) => (
                        <TableRow key={rows.id}>
                            <TableCell className={"table_main_container-body_number"} align="left">
                                <Link className={"table_main_container-body_number-link"}
                                      to={`../../pages/ReportDetailPage/${rows.id}`}>
                                    {rows.reporting_id}
                                </Link>
                            </TableCell>
                            <TableCell className={"table_main_container-body_person"} align="left">
                                {rows.who_call}
                            </TableCell>
                            <TableCell className={"table_main_container-body_place"} align="left">
                                {rows.location_accident}
                            </TableCell>
                            <TableCell className={"table_main_container-body_date"} align="left">
                                {rows.call_date}
                            </TableCell>
                            <TableCell className={"table_main_container-body_type"} align="left">
                                {rows.emergency_name}
                            </TableCell>
                            <TableCell className={"table_main_container-body_status"} align="left">
                                {rows.status === "done" ? <Success/> : <Danger/>}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ReportMainTable;