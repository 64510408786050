import { _transformBranches } from "./_transformBranches";
import { _transformIncidents } from "./_transformIncidents";

export const _transformQuantity = (data, selectedFilter) => {

    const incidents = Object.values(_transformIncidents);
    const rows = incidents.map((incident) => {
        const row = { name: incident };

        Object.keys(_transformBranches).forEach((branchId) => {
            row[`branch_${branchId}`] = 0;
        });

        row.total = 0;
        return row;
    });

    console.log("Initial rows:", rows);

    Object.keys(data).forEach(incidentId => {
        let branchData;

        if (selectedFilter === 'line_emergency') {
            branchData = data[incidentId].line_emergency;
        } else if (selectedFilter === 'agreement') {
            branchData = data[incidentId].agreement;
        } else {
            branchData = data[incidentId].branches;
        }

        console.log("Processing incidentId:", incidentId, "branchData:", branchData);

        if(!branchData) return

        Object.keys(branchData).forEach(branchId => {
            const incidentName = _transformIncidents[incidentId];
            const count = branchData[branchId];
            console.log("Incident:", incidentName, "BranchId:", branchId, "Count:", count);

            const row = rows.find(row => row.name === incidentName);
            if (row) {
                row[`branch_${branchId}`] += count;
                row.total += count;
            }
        });
    });

    console.log("Transformed rows:", rows);

    return rows;
};