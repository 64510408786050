import './DashboardInfoTable.scss';

import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { fetchTableDashboard } from "../../../slices/tableDashboardSlice";

import { Paper } from "@mui/material";
import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";

import { Success } from "../../../resources/Status/Status";
import { Danger } from "../../../resources/Status/Status";

import { AktobeBranch } from "../../../resources/Branch/Branch";
import { EastKazakhstanBranch } from "../../../resources/Branch/Branch";
import { AkmolaBranch } from "../../../resources/Branch/Branch";
import { SouthKazakhstanBranch } from "../../../resources/Branch/Branch";
import { WestKazakhstanBranch } from "../../../resources/Branch/Branch";
import { NorthKazakhstanBranch } from "../../../resources/Branch/Branch";
import { MangystauBranch } from "../../../resources/Branch/Branch";
import { PavlodarBranch } from "../../../resources/Branch/Branch";
import { KyzylordaBranch } from "../../../resources/Branch/Branch";
import { AtyrauBranch } from "../../../resources/Branch/Branch";
import { KaragandaBranch } from "../../../resources/Branch/Branch";


const DashboardInfoTable = () => {

    const dispatch = useDispatch();
    const departures = useSelector(state => state.tableDashboard.departures);
    const status = useSelector(state => state.tableDashboard.tableDashboardLoadingStatus);

    useEffect(() => {
        dispatch(fetchTableDashboard());
    }, [dispatch]);

    const branchIds = {
        "2": <AktobeBranch/>,
        "3": <AtyrauBranch/>,
        "4": <EastKazakhstanBranch/>,
        "5": <WestKazakhstanBranch/>,
        "6": <KaragandaBranch/>,
        "7": <KyzylordaBranch/>,
        "8": <MangystauBranch/>,
        "9": <PavlodarBranch/>,
        "10": <NorthKazakhstanBranch/>,
        "11": <SouthKazakhstanBranch/>,
        "12": <AkmolaBranch/>
    }

    return (
        <TableContainer elevation={3} component={Paper} className={"table_dashboard"}>
            <Table className={"table_dashboard_container"}>
                <TableHead className={"table_dashboard_container-header"}>
                    <TableRow className={"table_dashboard_container-header_row"}>
                        <TableCell align="left">Объект</TableCell>
                        <TableCell align="left">Дата</TableCell>
                        <TableCell align="left">Тип аварии</TableCell>
                        <TableCell align="left">Филиал</TableCell>
                        <TableCell align="left">Статус</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={"table_dashboard_container-body"}>
                    {departures.map((rows) => (
                        <TableRow key={rows.name}>
                            <TableCell className={"table_dashboard_container-body_place"} align="left">
                                {rows.location_accident}
                            </TableCell>
                            <TableCell className={"table_main_container-body_date"} align="left">
                                {rows.call_date}
                            </TableCell>
                            <TableCell className={"table_dashboard_container-body_type"} align="left">
                                {rows.emergency_name}
                            </TableCell>
                            <TableCell className={"table_operations_container-body_branch"} align="left">
                                {branchIds[rows.branch_id]}
                            </TableCell>
                            <TableCell className={"table_dashboard_container-body_status"} align="left">
                                {rows.status === "done" ? <Success/> : <Danger/>}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DashboardInfoTable;