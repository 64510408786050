import './ReportMain.scss';

import { useState } from "react";

import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { fetchTableMain } from "../../slices/tableMainSlice";
import { setSelectedInterval } from "../../slices/tableMainSlice";
import { setSelectedYear } from "../../slices/tableMainSlice";
import { setSelectedMonth } from "../../slices/tableMainSlice";
import { setSelectedWeek } from "../../slices/tableMainSlice";
import { setStartDate } from "../../slices/tableMainSlice";
import { setEndDate } from "../../slices/tableMainSlice";
import { setSelectedBranch } from "../../slices/tableMainSlice";
import { setSelectedLineEmergency } from "../../slices/tableMainSlice";

import { Box } from "@mui/material";
import { Divider } from "@mui/material";

import { LineDropdownFilter } from "../../resources/Dropdowns/Dropdowns";
import { BranchDropdown } from "../../resources/Dropdowns/Dropdowns";
import { IntervalDropdown } from "../../resources/Dropdowns/Dropdowns";
import { DateFilter } from "../../resources/Dropdowns/Dropdowns";
import { EmptyMessage } from "../../resources/Messages/Messages";
import { ErrorMessage } from "../../resources/Messages/Messages";
import { MainTitle } from "../../resources/Titles/Titles";
import { TableSkeleton } from "../../resources/Skeleton/Skeleton";

import Filters from "../Widgets/Filters/Filters";
import SearchPlaceholder from "../Widgets/SearchPlaceholder/SearchPlaceholder";
import ReportMainTable from "./ReportMainTable/ReportMainTable";


const ReportMain = () => {
    const dispatch = useDispatch();
    const departures = useSelector(state => state.tableMain.departures);
    const selectedBranch = useSelector(state => state.tableMain.selectedBranch);
    const selectedLineEmergency = useSelector(state => state.tableMain.selectedLineEmergency);
    const selectedInterval = useSelector(state => state.tableMain.selectedInterval);
    const selectedWeek = useSelector(state => state.tableMain.selectedWeek);
    const selectedYear = useSelector(state => state.tableMain.selectedYear);
    const selectedMonth = useSelector(state => state.tableMain.selectedMonth);
    const startDate = useSelector(state => state.tableMain.startDate);
    const endDate = useSelector(state => state.tableMain.endDate);
    // const searchData = useSelector(state => state.tableMain.searchData);
    const status = useSelector(state => state.tableMain.tableMainLoadingStatus);

    let content = null;

    // const filteredDepartures = _transformSearchDepartures(departures, searchData).filter(departure => {
    //     return selectedBranch ? departure.branch_id === selectedBranch : true;
    // });

    const createFilters = () => {
        let filters = {};

        if (selectedInterval === 'week') {
            filters.date = 'week'
        } else if (selectedInterval === 'month') {
            filters.date = 'month';
            filters.month = selectedMonth;
        } else if (selectedInterval === 'year') {
            filters.date = 'year';
            filters.year = selectedYear;
        } else if (selectedInterval === 'range') {
            filters.date_from = startDate;
            filters.date_to = endDate;
        }

        return filters;
    }

    const filteredDepartures = departures.filter(departure => {
        const currentBranch = selectedBranch ? departure.branch_id === selectedBranch : true;
        const currentLineEmergency = selectedLineEmergency
            ? (selectedLineEmergency === "line_emergency"
                ? departure.line_emergency === "Да" : departure.line_emergency === "Нет"
            ) : true

        return currentBranch && currentLineEmergency;
    });

    useEffect(() => {
        const filters = createFilters({
            selectedInterval,
            selectedYear,
            selectedMonth,
            startDate,
            endDate
        });


        dispatch(fetchTableMain(filters));
    }, [dispatch, selectedInterval, selectedYear, selectedMonth, startDate, endDate]);



    if (status === "loading") {
        content = <TableSkeleton/>
    } else if (departures.length === 0 || status === "error") {
        content = <ErrorMessage/>
    } else if (filteredDepartures.length === 0) {
        content = <EmptyMessage/>
    } else {
        content = <ReportMainTable departures={filteredDepartures}/>
    }

    return (
         <Box className={"report_main"}>
            <Box className={"report_main-container"}>
                <Box className={"report_main-container_title"}>
                    <Box className={"report_main-container_title-item"}>
                        <MainTitle initial={"Таблица оперативных донесений"}/>
                    </Box>
                    <Box className={"report_main-container_title-divider"}>
                        <Divider/>
                    </Box>
                </Box>
                <Box className={"report_main-container_search"}>
                    <Filters
                        branchFilter={
                            <BranchDropdown
                                initial={"Филиал"}
                                branch={selectedBranch}
                                setSelectedBranch={setSelectedBranch}
                            />
                        }
                        lineFilter={
                            <LineDropdownFilter
                                initial={"ЧС"}
                                selectedLineEmergency={selectedLineEmergency}
                                setSelectedLineEmergency={setSelectedLineEmergency}
                            />
                        }
                        intervalFilter={({setShowYear, setShowMonth, setShowRange}) => (
                            <IntervalDropdown
                                initial={"Интервал"}
                                interval={selectedInterval}
                                setFilterInterval={setSelectedInterval}
                                setShowYear={setShowYear}
                                setShowMonth={setShowMonth}
                                setShowRange={setShowRange}
                            />
                        )}
                        selectedYear={selectedYear}
                        setSelectedYear={setSelectedYear}
                        selectedMonth={selectedMonth}
                        setSelectedMonth={setSelectedMonth}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />
                </Box>
                <Box className={"report_main-container_table"}>
                    {content}
                </Box>
            </Box>
        </Box>
    )
}

export default ReportMain;