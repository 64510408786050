import './StatisticsBranches.scss';

import ReactECharts from 'echarts-for-react';

import { Link } from "react-router-dom";

import { Paper } from "@mui/material";
import { Box } from "@mui/material";
import { Divider } from "@mui/material";

import { MainTitle } from "../../../resources/Titles/Titles";
import { ChildTitle } from "../../../resources/Titles/Titles";

const options = {
    xAxis: {
        type: 'category',
        data: [
            'Актюбинский',
            'Атырауский',
            'Восточно-Казахстанский',
            'Западно-Казахстанский',
            'Карагандский',
            'Кызылординский',
            'Мангистауский',
            'Павлодарский',
            'Северо-Казахстанский',
            'Южно-Казахстанский',
            'Акмолинский'
        ],
        axisLabel: {
            interval: 0,
            rotate: 50
        }
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            data: [5000, 2809, 150, 80, 70, 140, 130, 90, 100, 300, 200],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            }
        }
    ]
}

const StatisticsBranches = () => {

    return (
        <Paper className={"accident_chart"}>
            <Box className={"accident_chart_container"}>
                <Box className={"accident_chart_container_title"}>
                    <MainTitle initial={"График чс по филиалам"}/>
                </Box>
                <Box className={"accident_chart_container_divider"}>
                    <Divider/>
                </Box>
                <Box className={"accident_chart_container_item"}>
                    <ReactECharts option={options}/>
                </Box>
                <Box className={"accident_chart_container_link"}>
                    <Link to={'#'} className={"accident_chart_container_link_item"}>
                        <ChildTitle initial={"Подробнее"}/>
                    </Link>
                </Box>
            </Box>
        </Paper>
    )
}

export default StatisticsBranches;