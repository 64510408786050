import './ReportDetailThird.scss';

import { Box } from "@mui/material";

import { SecondTitle } from "../../../resources/Titles/Titles";
import { ChildTitle } from "../../../resources/Titles/Titles";

const ReportDetailThird = (
    {
        use_transport,
        customer_technology,
        branch_workers,
        party_authority,
        other_data
    }
) => {

    return (
        <Box className={"report_detail_third"}>
            <Box className={"report_detail_third_container"}>
                <Box className={"report_detail_third_container_row"}>
                    <Box className={"report_detail_third_container_row_title"}>
                        <SecondTitle initial={"17. Количество и вид транспорта: "}/>
                    </Box>
                    <Box className={"report_detail_third_container_row_description"}>
                        <ChildTitle initial={use_transport}/>
                    </Box>
                </Box>
                <Box className={"report_detail_third_container_row"}>
                    <Box className={"report_detail_third_container_row_title"}>
                        <SecondTitle initial={"18. Техника заказчика: "}/>
                    </Box>
                    <Box className={"report_detail_third_container_row_description"}>
                        <ChildTitle initial={customer_technology}/>
                    </Box>
                </Box>
                <Box className={"report_detail_third_container_row"}>
                    <Box className={"report_detail_third_container_row_title"}>
                        <SecondTitle initial={"19. Количество задействованных работников филиала, отработанных чел/ч: "}/>
                    </Box>
                    <Box className={"report_detail_third_container_row_description"}>
                        <ChildTitle initial={branch_workers}/>
                    </Box>
                </Box>
                <Box className={"report_detail_third_container_row"}>
                    <Box className={"report_detail_third_container_row_title"}>
                        <SecondTitle initial={"20. Количество сторонних организаций: "}/>
                    </Box>
                    <Box className={"report_detail_third_container_row_description"}>
                        <ChildTitle initial={party_authority}/>
                    </Box>
                </Box>
                <Box className={"report_detail_third_container_row"}>
                    <Box className={"report_detail_third_container_row_title"}>
                        <SecondTitle initial={"21. Другое: "}/>
                    </Box>
                    <Box className={"report_detail_third_container_row_description"}>
                        <ChildTitle initial={other_data}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportDetailThird;