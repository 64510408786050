export const request = async (
    url = "https://192.168.1.13/api/departures.list/",
    method,
    body,
    credentials = "include"
) => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json; charset=utf-8',
        'Api-Key': 'd0767d7d:173E54Ez',
    };

    console.log("Request URL:", url);
    console.log("Request Method:", method);
    console.log("Request Body:", body);
    console.log("Request Headers:", headers);
    console.log("Request Credentials:", credentials);

    const options = {
        method,
        body,
        headers,
        credentials
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    try {
        const response = await fetch(url, options);

        console.log("Response:", response);

        if (!response.ok) {
            throw new Error(`Could not fetch ${url}, status: ${response.status}`);
        }

        const data = await response.json();

        console.log("Response Data:", data);

        return data;
    } catch (e) {
        console.error("Fetch error:", e);
        throw e;
    }
};