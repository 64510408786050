import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createEntityAdapter } from "@reduxjs/toolkit";

import { request } from "../api/api";

import { _transformDepartureDashboard } from "../services/_transformDepartureDashboard";

const tableDashboardAdapter = createEntityAdapter();

const initialState = tableDashboardAdapter.getInitialState({
    departures: [],
    tableDashboardLoadingStatus: 'idle'
});

export const fetchTableDashboard = createAsyncThunk(
    'tableDashboard/fetchTableDashboard',
    async() => {
        const data = await request('https://b24.centrspas.kz/api/departures.list/');
        return data.departures.map(_transformDepartureDashboard);
    }
);

const tableDashboardSlice = createSlice({
    name: 'tableDashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTableDashboard.pending, (state) => {
                state.tableDashboardLoadingStatus = 'loading';
            })
            .addCase(fetchTableDashboard.fulfilled, (state, action) => {
                state.departures = action.payload;
                state.tableDashboardLoadingStatus = 'idle';
            })
            .addCase(fetchTableDashboard.rejected, (state) => {
                state.tableDashboardLoadingStatus = 'error';
            });
    }
})

export default tableDashboardSlice.reducer;