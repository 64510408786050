import ReactECharts from "echarts-for-react";

import { Paper } from "@mui/material";
import { Box } from "@mui/material";
import EChartsReact from "echarts-for-react";

const options = {
    grid: {
        left: '10%',
        right: '10%',
        top: '10%',
        bottom: '50%'
    },
    xAxis: {
        type: 'category',
        data: [
            'Актюбинский',
            'Атырауский',
            'Восточно-Казахстанский',
            'Западно-Казахстанский',
            'Карагандский',
            'Кызылординский',
            'Мангистауский',
            'Павлодарский',
            'Северо-Казахстанский',
            'Южно-Казахстанский',
            'Акмолинский'
        ],
        axisLabel: {
            interval: 0,
            rotate: 50
        }
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            data: [
                {
                    value: 6,
                    itemStyle: {
                        color: '#ec407a'
                    }
                },
                {
                    value: 6,
                    itemStyle: {
                        color: '#ff9800'
                    }
                },
                {
                    value: 3,
                    itemStyle: {
                        color: '#ffc107'
                    }
                },
                {
                    value: 2,
                    itemStyle: {
                        color: '#4caf50'
                    }
                },
                {
                    value: 7,
                    itemStyle: {
                        color: '#9c27b0'
                    }
                },
                {
                    value: 10,
                    itemStyle: {
                        color: '#ff5722'
                    }
                },
                {
                    value: 9,
                    itemStyle: {
                        color: '#00bcd4'
                    }
                },
                {
                    value: 13,
                    itemStyle: {
                        color: '#03a9f4'
                    }
                },
                {
                    value: 18,
                    itemStyle: {
                        color: '#3f51b5'
                    }
                },
                {
                    value: 22,
                    itemStyle: {
                        color: '#f44336'
                    }
                },
                {
                    value: 23,
                    itemStyle: {
                        color: '#009688'
                    }
                }
            ],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            }
        }
    ]
}

const StatisticsWorkers = () => {

    return (
        <Paper className={"statistics_workers"}>
            <Box className={"statistics_workers_container"}>
                <EChartsReact option={options}/>
            </Box>
        </Paper>
    )
}

export default StatisticsWorkers;