import './ReportThirdModal.scss';

import { Box } from "@mui/material";
import { Paper } from "@mui/material";
import { Divider } from "@mui/material";
import { TextField } from "@mui/material";

import { MainTitle, SecondTitle } from "../../../resources/Titles/Titles";

const ReportThirdModal = () => {

    return (
        <Paper elevation={3}
               className={"report_modal_third"}
               sx={{borderRadius: 3}}>
            <Box className={"report_modal_third_container"}>
                <Box className={"report_modal_third_container_title"}>
                    <MainTitle initial={"Задействовано"}/>
                    <Divider/>
                </Box>
                <Box className={"report_modal_third_container_input"}>
                    <Box className={"report_modal_third_container_input-title"}>
                        <SecondTitle initial={"АЦ"}/>
                    </Box>
                    <TextField
                        required
                        type="number"
                        inputProps={{min: 0}}
                        id="outlined-requared"
                        className={"report_modal_third_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_third_container_input"}>
                    <Box className={"report_modal_third_container_input-title"}>
                        <SecondTitle initial={"Грузовик"}/>
                    </Box>
                    <TextField
                        required
                        type="number"
                        inputProps={{min: 0}}
                        id="outlined-requared"
                        className={"report_modal_third_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_third_container_input"}>
                    <Box className={"report_modal_third_container_input-title"}>
                        <SecondTitle initial={"Автобус"}/>
                    </Box>
                    <TextField
                        required
                        type="number"
                        inputProps={{min: 0}}
                        id="outlined-requared"
                        className={"report_modal_third_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_third_container_input"}>
                    <Box className={"report_modal_third_container_input-title"}>
                        <SecondTitle initial={"Иная техника"}/>
                    </Box>
                    <TextField
                        required
                        type="number"
                        inputProps={{min: 0}}
                        id="outlined-requared"
                        className={"report_modal_third_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_third_container_input"}>
                    <Box className={"report_modal_third_container_input-title"}>
                        <SecondTitle initial={"Колличество работников филиала"}/>
                        <span>*</span>
                    </Box>
                    <TextField
                        required
                        type="number"
                        inputProps={{min: 0}}
                        id="outlined-requared"
                        className={"report_modal_third_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_third_container_input"}>
                    <Box className={"report_modal_third_container_input-title"}>
                        <SecondTitle initial={"Колличество сторонних орг."}/>
                    </Box>
                    <TextField
                        required
                        type="number"
                        inputProps={{min: 0}}
                        id="outlined-requared"
                        className={"report_modal_third_container_input-item"}
                    />
                </Box>
                <Box className={"report_modal_third_container_input"}>
                    <Box className={"report_modal_third_container_input-title"}>
                        <SecondTitle initial={"Другое"}/>
                    </Box>
                    <TextField
                        required
                        multiline
                        inputProps={{
                            style: {
                                height: "200px"
                            }
                        }}
                        id="outlined-requared"
                        className={"report_modal_third_container_input-item"}
                    />
                </Box>
            </Box>
        </Paper>
    )
}

export default ReportThirdModal;