import './DashboardPage.scss';

import { Link } from "react-router-dom";

import { Box } from "@mui/material";

import Header from "../../components/Widgets/Header/Header";
import DashboardCounter from "../../components/DashboardCounter/DashboardCounter";
import DashboardInfo from "../../components/DashboardTable/DashboardInfo";
import DashboardMap from "../../components/DashboardMap/DashboardMap";
import Navigation from "../../components/Widgets/Navigation/Navigation";

import { ButtonBack } from "../../resources/Buttons/Buttons";
import { ButtonPromptDeparture } from "../../resources/Buttons/Buttons";
import { MainBranch } from "../../resources/Branch/Branch";
import { ButtonOperations } from "../../resources/Buttons/Buttons";
import { ButtonStatistic } from "../../resources/Buttons/Buttons";

const DashboardPage = () => {

    return (
        <Box className={"dashboard_page"}>
            <Header/>
            <Box className={"dashboard_page_container"}>
                <Box className={"dashboard_page_container-widget"}>
                    <Navigation
                        branch={
                            <MainBranch/>
                        }
                        backLink={
                            <Link to="../Pages/ReportMainPage">
                                <ButtonBack
                                    initial={"Назад"}
                                />
                            </Link>
                        }
                        statisticLink={
                            <Link to="../components/AnalyticsSection">
                                <ButtonStatistic
                                    initial={"Статистика"}
                                />
                            </Link>
                        }
                    />
                </Box>
                <Box className={"dashboard_page_container-content"}>
                    <DashboardCounter/>
                    <DashboardMap/>
                </Box>
                <Box>
                    <DashboardInfo/>
                </Box>
            </Box>
        </Box>
    )
}

export default DashboardPage