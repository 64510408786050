import './OperationsMainTable.scss';

import { useState } from "react";

import { Paper } from "@mui/material";

import { Table } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableContainer } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";

import { AktobeBranch } from "../../../resources/Branch/Branch";
import { EastKazakhstanBranch } from "../../../resources/Branch/Branch";
import { AkmolaBranch } from "../../../resources/Branch/Branch";
import { SouthKazakhstanBranch } from "../../../resources/Branch/Branch";
import { WestKazakhstanBranch } from "../../../resources/Branch/Branch";
import { NorthKazakhstanBranch } from "../../../resources/Branch/Branch";
import { MangystauBranch } from "../../../resources/Branch/Branch";
import { PavlodarBranch } from "../../../resources/Branch/Branch";
import { KyzylordaBranch } from "../../../resources/Branch/Branch";
import { AtyrauBranch } from "../../../resources/Branch/Branch";
import { KaragandaBranch } from "../../../resources/Branch/Branch";

const OperationsMainTable = ({departures}) => {
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const handleRowClick = (index) => {
        setSelectedRowIndex(index)
    }

    const branchIds = {
        "2": <AktobeBranch/>,
        "3": <AtyrauBranch/>,
        "4": <EastKazakhstanBranch/>,
        "5": <WestKazakhstanBranch/>,
        "6": <KaragandaBranch/>,
        "7": <KyzylordaBranch/>,
        "8": <MangystauBranch/>,
        "9": <PavlodarBranch/>,
        "10": <NorthKazakhstanBranch/>,
        "11": <SouthKazakhstanBranch/>,
        "12": <AkmolaBranch/>
    }

    return (
        <TableContainer
            elevation={3}
            component={Paper}
            className={"table_operations"}
        >
            <Table className={"table_operations_container"}>
                <TableHead className={"table_operations_container-header"} sx={{backgroundColor: "#1976d2"}}>
                    <TableRow className={"table_operations_container-header_row"}>
                        <TableCell sx={{color: "#fff"}} align="left">
                            №
                        </TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">
                            Дата начала
                        </TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">
                            Дата завершения
                        </TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">
                            Владелец объекта
                        </TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">
                            Наименование аварийной ситуации
                        </TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">
                            Место возникновения аварийной ситуации
                        </TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">
                            Работало на ликвидации аварии
                        </TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">
                            Применяемое спец.оборудование, автотранспорт
                        </TableCell>
                        <TableCell sx={{color: "#fff"}} align="left">
                            Филиалы
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={"table_operations_container-body"}>
                    {departures.map((rows) => (
                        <TableRow key={rows.name}
                                  onClick={() => handleRowClick(rows)}
                                  sx={{
                                      backgroundColor: selectedRowIndex === rows ? "#4dabf5" : "inherit",
                                      cursor: "pointer",
                                  }}
                        >
                            <TableCell
                                sx={{color: selectedRowIndex === rows ? "#fff" : "inherit"}}
                                className={"table_operations_container-body_number"} align="left">
                                {rows.reporting_id}
                            </TableCell>
                            <TableCell
                                sx={{color: selectedRowIndex === rows ? "#fff" : "inherit"}}
                                className={"table_operations_container-body_start"} align="left">
                                {rows.arrival_date === null ? "Не заполнено" : rows.arrival_date}
                            </TableCell>
                            <TableCell
                                sx={{color: selectedRowIndex === rows ? "#fff" : "inherit"}}
                                className={"table_operations_container-body_done"} align="left">
                                {rows.finish_work_date === null ? "Не заполнено" : rows.finish_work_date}
                            </TableCell>
                            <TableCell
                                sx={{color: selectedRowIndex === rows ? "#fff" : "inherit"}}
                                className={"table_operations_container-body_owner"} align="left">
                                {rows.object_owner}
                            </TableCell>
                            <TableCell
                                sx={{color: selectedRowIndex === rows ? "#fff" : "inherit"}}
                                className={"table_operations_container-body_emergency"} align="left">
                                {rows.emergency_name}
                            </TableCell>
                            <TableCell
                                sx={{color: selectedRowIndex === rows ? "#fff" : "inherit"}}
                                className={"table_operations_container-body_location"} align="left">
                                {rows.location_accident}
                            </TableCell>
                            <TableCell
                                sx={{color: selectedRowIndex === rows ? "#fff" : "inherit"}}
                                className={"table_operations_container-body_workers"} align="left">
                                {rows.branch_workers === null ? "Не заполнено" : rows.branch_workers}
                            </TableCell>
                            <TableCell
                                sx={{color: selectedRowIndex === rows ? "#fff" : "inherit"}}
                                className={"table_operations_container-body_transport"} align="left">
                                {rows.use_transport.length === 0 ? "Транспорт не указан" : rows.use_transport}
                            </TableCell>
                            <TableCell
                                sx={{color: selectedRowIndex === rows ? "#fff" : "inherit"}}
                                className={"table_operations_container-body_branch"} align="left">
                                {branchIds[rows.branch_id]}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OperationsMainTable;

