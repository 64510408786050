import './CounterWrapper.scss';
import './media.scss';

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { fetchCounterValue } from "../../../slices/counterValueSlice";

import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material";

import { Fire } from "../CounterCards/CounterCards";
import { Injury } from "../CounterCards/CounterCards";
import { PowerOutage } from "../CounterCards/CounterCards";
import { Collapse } from "../CounterCards/CounterCards";
import { Accident } from "../CounterCards/CounterCards";
import { GettingStuck } from "../CounterCards/CounterCards";
import { Disease } from "../CounterCards/CounterCards";
import { Flooding } from "../CounterCards/CounterCards";
import { FireProtection } from "../CounterCards/CounterCards";
import { GasPollution } from "../CounterCards/CounterCards";
import { Explosion } from "../CounterCards/CounterCards";
import { TrafficAccident } from "../CounterCards/CounterCards";
import { SearchPeople } from "../CounterCards/CounterCards";
import { OutdoorFountain } from "../CounterCards/CounterCards";
import { Bottling } from "../CounterCards/CounterCards";
import { ErrorMessage } from "../../../resources/Messages/Messages";


const accidentComponents = {
    "01": Fire,
    "02": Injury,
    "03": PowerOutage,                                        
    "04": Collapse,
    "05": Accident,
    "06": GettingStuck,
    "07": Disease,
    "08": Flooding,
    "09": TrafficAccident,
    "10": GasPollution,
    "11": Explosion,
    "12": FireProtection,
    "13": SearchPeople,
    "14": OutdoorFountain,
    "15": Bottling
};

const accidentTitles = {
    "01": "Пожар",
    "02": "Травма",
    "03": "Отк. электричества",
    "04": "Обрушение",
    "05": "Несчастный случай",
    "06": "Застревание",
    "07": "Заболевание",
    "08": "Затопление",
    "09": "ДТП",
    "10": "Загазованность",
    "11": "Взрыв",
    "12": "Обеспечение",
    "13": "Поиск людей",
    "14": "Открытый фонтан",
    "15": "Розлив"
};

const CounterWrapper = () => {
    const dispatch = useDispatch();
    const accidents = useSelector((state) => state.counterValue.accidents);
    const status = useSelector((state) => state.counterValue.counterValueLoadingStatus);

    useEffect(() => {
        dispatch(fetchCounterValue());
    }, [dispatch]);

    if (accidents === undefined) {
        return <ErrorMessage/>
    }

    return (
        <Box className={"accident_counter_wrapper"}>
            <Box className={"accident_counter_wrapper-container"}>
                {Object.keys(accidentComponents).map((id) => {
                    const Component = accidentComponents[id];
                    return (
                        <Box key={id} className={"accident_counter_wrapper-container_items"}>
                            <Component
                                accidentTitle={accidentTitles[id]}
                                accidentCount={
                                status === 'loading' ?
                                    <CircularProgress color={"inherit"} size={20}/> : (accidents[id] || "0")
                                }
                            />
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
}

export default CounterWrapper;