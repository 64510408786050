import  './ReportDetail.scss';

import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { fetchReportDetail } from "../../slices/detailSlice";

import { Box } from "@mui/material";

import ReportDetailFirst from "./ReportDetailFirst/ReportDetailFirst";
import ReportDetailSecond from "./ReportDetailSecond/ReportDetailSecond";
import ReportDetailThird from "./ReportDetailThird/ReportDetailThird";

import { MainTitle } from "../../resources/Titles/Titles";

const ReportDetail = ({reportId}) => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.reportDetail.data);
    const status = useSelector(state => state.reportDetail.detailLoadingStatus);

    const branchId = {
        "2": "Актюбинский",
        "3": "Атырауский",
        "4": "Восточно-Казахстанский",
        "5": "Западно-Казахстанский",
        "6": "Карагандинский",
        "7": "Кызылординский",
        "8": "Мансгистауский",
        "9": "Павлодарский",
        "10": "Северно-Казахстанский",
        "11": "Южно-Казахстанский",
        "12": "Акмолинский"
    }

    useEffect(() => {
        if (reportId) {
            dispatch(fetchReportDetail(reportId))
        }
    }, [dispatch, reportId]);


    return (
        <Box className={"report_detail"}>
            <Box className={"report_detail_container"}>
                <Box className={"report_detail_container_row"}>
                    <Box className={"report_detail_container_row_title"}>
                        <MainTitle initial={"Основные данные"}/>
                    </Box>
                    <Box className={"report_detail_container_row_description"}>
                        <ReportDetailFirst
                            branch_id={
                                branchId[data.branch_id]
                            }
                            reporting_id={
                                data.reporting_id
                            }
                            who_call={
                                data.who_call
                            }
                            location_accident={
                                data.location_accident
                            }
                            reporting_date={
                                data.reporting_date
                            }
                            arrival_date={
                                data.arrival_date
                            }
                            finish_work_date={
                                data.finish_work_date
                            }
                            object_owner={
                                data.object_owner
                            }
                            object_data={
                                data.object_data === null ? "Не заполнено" : data.object_data
                            }
                            emergency_name={
                                data.emergency_name
                            }
                            call_date={
                                data.call_date
                            }
                        />
                    </Box>
                    <Box className={"report_detail_container_row_title second"}>
                        <MainTitle initial={"Дополнительные сведения"}/>
                    </Box>
                    <Box className={"report_detail_container_row_description"}>
                        <ReportDetailSecond
                            minor_injury={
                                data.minor_injury === null ? "0" : data.minor_injury
                            }
                            severe_injury={
                                data.severe_injury === null ? "0" : data.severe_injury
                            }
                            fatal_injury={
                                data.fatal_injury === null ? "0" : data.fatal_injury
                            }
                            people_evacuated={
                                data.people_evacuated === null ? "0" : data.people_evacuated
                            }
                            provided_medical={
                                data.provided_medical === null ? "0" : data.provided_medical
                            }
                            scope_work={
                                data.scope_work === null ? "Не заполнено" : data.scope_work
                            }
                            situation_filing={
                                data.situation_filing === null ? "Не заполнено" : data.situation_filing
                            }
                        />
                    </Box>
                    <Box className={"report_detail_container_row_title second"}>
                        <MainTitle initial={"Задействована техника"}/>
                    </Box>
                    <Box className={"report_detail_container_row_description"}>
                        <ReportDetailThird
                            use_transport={
                                data.use_transport === null ? "Транспорт не указан" : data.use_transport
                            }
                            customer_technology={
                                data.customer_technology === null ? "Транспорт не указан" : data.customer_technology
                            }
                            branch_workers={
                                data.branch_workers === null ? "Не заполнено" : data.branch_workers
                            }
                            party_authority={
                                data.party_authority === null ? "Не заполнено" : data.party_authority
                            }
                            other_data={
                                data.other_data === null ? "Не заполнено" : data.other_data
                            }
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ReportDetail;